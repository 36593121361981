import { path, paths } from './util';

export const ROOT_OPPORTUNITIES = '/opportunities';
export const ROOT_REGISTRATION = '/registration';
export const ROOT_PORTFOLIO = '/portfolio';
export const ROOT_RESET_PASSWORD = '/reset-password';
export const ROOT_AUTH = '/auth';

export const PATH_ROOT = '/';

export const PATH_RESET_PASSWORD = {
  resetPassword: ROOT_RESET_PASSWORD,
  recoveryEmailSent: path(ROOT_RESET_PASSWORD, 'recovery-email-sent'),
  verifyEmail: path(ROOT_RESET_PASSWORD, 'verify-email'),
  newPassword: path(ROOT_RESET_PASSWORD, 'new-password'),
};

export const PATH_PRE_ENTRY = {
  description: path(ROOT_OPPORTUNITIES, ':opportunityId/pre-entry/description'),
  documents: path(ROOT_OPPORTUNITIES, ':opportunityId/pre-entry/documents'),
  form: path(ROOT_OPPORTUNITIES, ':opportunityId/pre-entry/form'),
  confirm: path(ROOT_OPPORTUNITIES, ':opportunityId/pre-entry/confirm'),
  complete: path(ROOT_OPPORTUNITIES, ':opportunityId/pre-entry/complete'),
};

export const PATH_ENTRY = {
  prospectus: path(ROOT_OPPORTUNITIES, ':opportunityId/entry/prospectus'),
  consent: path(ROOT_OPPORTUNITIES, ':opportunityId/entry/consent'),
  confirm: path(ROOT_OPPORTUNITIES, ':opportunityId/entry/confirm'),
  complete: path(ROOT_OPPORTUNITIES, ':opportunityId/entry/complete'),
};

export const PATH_OPPORTUNITIES = {
  opportunities: ROOT_OPPORTUNITIES,
  complete: path(ROOT_OPPORTUNITIES, 'complete'),
  opportunity: path(ROOT_OPPORTUNITIES, ':opportunityId'),
};

export const PATH_PORTFOLIO = {
  portfolios: ROOT_PORTFOLIO,
  portfolio: path(ROOT_PORTFOLIO, ':id'),
};

export const PATH_NEW_ACCOUNT = {
  registration: ROOT_REGISTRATION,
  verifyEmail: path(ROOT_REGISTRATION, 'verify-email'),
  verificationEmailSent: path(ROOT_REGISTRATION, 'verification-email-sent'),
};

export const PATH_REGISTRATION = {
  termsOfUse: path(ROOT_REGISTRATION, 'terms-of-use'),
  connectNetAnswer: path(ROOT_REGISTRATION, 'connect-net-answer'),
  electronicDelivery: path(ROOT_REGISTRATION, 'electronic-delivery'),
  profile: path(ROOT_REGISTRATION, 'profile'),
  bank: path(ROOT_REGISTRATION, 'bank'),
  compatibility: path(ROOT_REGISTRATION, 'compatibility'),
  otherInformation: path(ROOT_REGISTRATION, 'other-information'),
  confirm: path(ROOT_REGISTRATION, 'confirm'),
  kyc: path(ROOT_REGISTRATION, 'kyc'),
  verifyKycStatus: path(ROOT_REGISTRATION, 'verify-kyc-status'),
};

export const PATH_SAISON_CONNECT = {
  oauthVerify: path(ROOT_AUTH, 'saison-connect/oauth/verify'),
  twoFaVerify: path(ROOT_AUTH, 'saison-connect/2fa/verify'),
};

export const PATH_AUTH = {
  login: '/login',
  logout: '/logout',
};

export const PATH_USER = {
  profile: '/profile',
  security: '/security',
};

export const PATH_OTHER = {
  documents: '/documents',
  tokens: '/tokens',
  faq: '/faq',
  notFound: '/not-found',
};

export const MAIN_CONTAINER_PATHS = [
  ...paths(PATH_OPPORTUNITIES),
  ...paths(PATH_OTHER),
  ...paths(PATH_USER),
  ...paths(PATH_PORTFOLIO),
];

export const SIMPLE_CONTAINER_PATHS = [
  ...paths(PATH_AUTH),
  ...paths(PATH_RESET_PASSWORD),
  ...paths(PATH_NEW_ACCOUNT),
  ...paths(PATH_SAISON_CONNECT),
];
