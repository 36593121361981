import { Next } from '../next';

export const getSaisonConnectLoginPageLinksNext = new Next(
  '/external-system-integrations/saison-connect/links',
  'GET_SAISON_CONNECT_LOGIN_PAGE_LINKS',
  {
    method: 'GET',
  },
);

export const saisonConnectOAuthStatusNext = new Next(
  '/external-system-integrations/saison-connect/oauth/status',
  'SAISON_CONNECT_OAUTH_STATUS',
  {
    method: 'GET',
  },
);

export const saisonConnectOAuthLoginNext = new Next(
  '/external-system-integrations/saison-connect/oauth/login',
  'SAISON_CONNECT_OAUTH_LOGIN',
  {
    method: 'POST',
  },
);

export const saisonConnectOAuthRefreshNext = new Next(
  '/external-system-integrations/saison-connect/oauth/refresh',
  'SAISON_CONNECT_OAUTH_REFRESH',
  {
    method: 'GET',
  },
);

export const saisonConnectConnectNext = new Next(
  '/external-system-integrations/saison-connect/connect',
  'SAISON_CONNECT_CONNECT',
  {
    method: 'GET',
  },
);
