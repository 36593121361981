import React, { PropsWithChildren } from 'react';
import { PageTitle } from './PageTitle';
import { ReturnLink } from '../ReturnLink';
import UserData from './UserData';
import './PageHeader.scss';
import { useSettings } from '@state/setting/hooks';
import classNames from 'classnames';

export interface IPageHeader {
  title?: string;
  subtitle?: string;
  noUserMenu?: boolean;
  showBackButton?: boolean;
  legacy?: boolean;
  action?: JSX.Element;
}

const PageHeader = ({
  title,
  subtitle,
  action,
  noUserMenu = false,
  legacy = false,
  showBackButton = false,
}: PropsWithChildren<IPageHeader>) => {
  const {
    dashboardDisplayFlags: { enableMenuNavigation, enableUserMenu },
  } = useSettings();

  return !noUserMenu || !!title ? (
    <div className='PageHeaderComponent'>
      <div
        className={classNames({
          header: enableMenuNavigation,
          headerWithoutUserMenu: !enableMenuNavigation,
        })}
      >
        {title ? (
          <PageTitle title={title} subtitle={subtitle} />
        ) : (
          <div>{showBackButton && <ReturnLink className='max-width-180 w-100' />}</div>
        )}
        {action && <div className='action'>{action}</div>}
        {!noUserMenu && enableUserMenu && <UserData legacy={legacy} />}
      </div>
      {showBackButton && title && <ReturnLink className='return-link mb-4 max-width-180 w-100' />}
    </div>
  ) : (
    <></>
  );
};

export default PageHeader;
