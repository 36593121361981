import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { translate } from './translate';
import * as zenginCode from 'zengin-code';
import { ComboboxOption } from '@components/Form/Combobox/Combobox';

dayjs.extend(localeData);

export const birthYears = () => {
  const minYear = dayjs().subtract(18, 'year').year();
  const maxYear = dayjs().subtract(130, 'year').year();

  return [
    { label: '', value: '' },
    ...[{ label: '', value: '' }, ...Array(minYear - maxYear)]
      .map((_, i) => ({
        label: translate('Texts.unit.year', { year: maxYear + i }),
        value: String(maxYear + i),
      }))
      .reverse(),
  ];
};

export const birthMonths = () => {
  return [
    { label: '', value: '' },
    ...dayjs.monthsShort().map((v, i) => ({
      label: v,
      value: String(i + 1),
    })),
  ];
};

export const getDaysOfMonth = (month: number, year = dayjs().year()) => {
  const numberOfDays = dayjs(`${year}-${month}`, 'YYYY-MM').daysInMonth();
  return [...Array(numberOfDays)].map((_, i) => ({
    label: translate('Texts.unit.day', { day: i + 1 }),
    value: String(i + 1),
  }));
};

export const incomeSource = () => [
  { label: '', value: '' },
  {
    label: translate('Texts.compatibility.main-source-of-income.business-income'),
    value: 'business-income',
  },
  {
    label: translate('Texts.compatibility.main-source-of-income.real-estate-income'),
    value: 'real-estate-income',
  },
  {
    label: translate('Texts.compatibility.main-source-of-income.employment-income'),
    value: 'employment-income',
  },
  {
    label: translate('Texts.compatibility.main-source-of-income.interest-and-dividend-income'),
    value: 'interest-and-dividend-income',
  },
  {
    label: translate('Texts.compatibility.main-source-of-income.pension'),
    value: 'pension',
  },
  {
    label: translate('Texts.compatibility.main-source-of-income.head-of-household-income'),
    value: 'head-of-household-income',
  },
  {
    label: translate('Texts.compatibility.main-source-of-income.otherwise'),
    value: 'otherwise',
  },
  {
    label: translate('Texts.compatibility.main-source-of-income.none'),
    value: 'none',
  },
];

export const jobs = () => [
  { label: '', value: '' },
  {
    label: translate('Texts.compatibility.job.managers-and-officials'),
    value: 'managers-and-officials',
  },
  {
    label: translate('Texts.compatibility.job.company-employee-full-time'),
    value: 'company-employee-full-time',
  },
  {
    label: translate('Texts.compatibility.job.company-employee-contract-employees'),
    value: 'company-employee-contract-employees',
  },
  {
    label: translate('Texts.compatibility.job.company-employee-temporary-employees'),
    value: 'company-employee-temporary-employees',
  },
  {
    label: translate('Texts.compatibility.job.part-time-job'),
    value: 'part-time-job',
  },
  {
    label: translate('Texts.compatibility.job.public-officials'),
    value: 'public-officials',
  },
  {
    label: translate('Texts.compatibility.job.self-employed'),
    value: 'self-employed',
  },
  {
    label: translate('Texts.compatibility.job.free-lance'),
    value: 'free-lance',
  },
  {
    label: translate('Texts.compatibility.job.housewife'),
    value: 'housewife',
  },
  {
    label: translate('Texts.compatibility.job.college-students'),
    value: 'college-students',
  },
  {
    label: translate('Texts.compatibility.job.professional-school-students'),
    value: 'professional-school-students',
  },
  {
    label: translate('Texts.compatibility.job.high-school-students'),
    value: 'high-school-students',
  },
  {
    label: translate('Texts.compatibility.job.doctor'),
    value: 'doctor',
  },
  {
    label: translate('Texts.compatibility.job.shigyo'),
    value: 'shigyo',
  },
  {
    label: translate('Texts.compatibility.job.npo-corporation-staff'),
    value: 'npo-corporation-staff',
  },
  {
    label: translate('Texts.compatibility.job.stay-at-home'),
    value: 'stay-at-home',
  },
  {
    label: translate('Texts.compatibility.job.unemployed'),
    value: 'unemployed',
  },
  {
    label: translate('Texts.compatibility.job.retirement'),
    value: 'retirement',
  },
];

export const annualIncomes = () => [
  { label: '', value: '' },
  {
    label: translate('Texts.compatibility.annual-income.less-than-3million-yen'),
    value: 'less-than-3million-yen',
  },
  {
    label: translate('Texts.compatibility.annual-income.3-5million-yen'),
    value: '3-5million-yen',
  },
  {
    label: translate('Texts.compatibility.annual-income.5-10million-yen'),
    value: '5-10million-yen',
  },
  {
    label: translate('Texts.compatibility.annual-income.10-20million-yen'),
    value: '10-20million-yen',
  },
  {
    label: translate('Texts.compatibility.annual-income.20-30million-yen'),
    value: '20-30million-yen',
  },
  {
    label: translate('Texts.compatibility.annual-income.30million-yen-more'),
    value: '30million-yen-more',
  },
];

export const amountOfAssets = () => [
  { label: '', value: '' },
  {
    label: translate('Texts.compatibility.asset-amount.less-than-3million-yen'),
    value: 'less-than-3million-yen',
  },
  {
    label: translate('Texts.compatibility.asset-amount.3-10-million-yen'),
    value: '3-10-million-yen',
  },
  {
    label: translate('Texts.compatibility.asset-amount.10-30-million-yen'),
    value: '10-30-million-yen',
  },
  {
    label: translate('Texts.compatibility.asset-amount.30-50-million-yen'),
    value: '30-50-million-yen',
  },
  {
    label: translate('Texts.compatibility.asset-amount.50-100-million-yen'),
    value: '50-100-million-yen',
  },
  {
    label: translate('Texts.compatibility.asset-amount.100-million-yen-more'),
    value: '100-million-yen-more',
  },
];

export const kindOfFunds = () => [
  { label: '', value: '' },
  {
    label: translate('Texts.compatibility.asset-type.surplus-funds'),
    value: 'surplus-funds',
  },
  {
    label: translate('Texts.compatibility.asset-type.reserve-fund'),
    value: 'reserve-fund',
  },
  {
    label: translate('Texts.compatibility.asset-type.living-expenses'),
    value: 'living-expenses',
  },
];

export const investmentPurposes = () => [
  { label: '', value: '' },
  {
    label: translate('Texts.compatibility.investment-purpose.long-term-asset-management'),
    value: 'long-term-asset-management',
  },
  {
    label: translate('Texts.compatibility.investment-purpose.short-term-price-movements'),
    value: 'short-term-price-movements',
  },
  {
    label: translate('Texts.compatibility.investment-purpose.otherwise'),
    value: 'otherwise',
  },
];

export const investmentPolicies = () => [
  { label: '', value: '' },
  {
    label: translate('Texts.compatibility.investment-policy.safety-of-principal'),
    value: 'safety-of-principal',
  },
  {
    label: translate('Texts.compatibility.investment-policy.stable-income'),
    value: 'stable-income',
  },
  {
    label: translate('Texts.compatibility.investment-policy.price-increases-with-distribution'),
    value: 'price-increases-with-distribution',
  },
  {
    label: translate('Texts.compatibility.investment-policy.price-increases'),
    value: 'price-increases',
  },
  {
    label: translate('Texts.compatibility.investment-policy.high-price-increases'),
    value: 'high-price-increases',
  },
];

export const experienceYears = () => [
  {
    label: translate('Texts.compatibility.investment-experience.none'),
    value: 'none',
  },
  {
    label: translate('Texts.compatibility.investment-experience.less-than-1-year'),
    value: 'less-than-1-year',
  },
  {
    label: translate('Texts.compatibility.investment-experience.less-than-3-years'),
    value: 'less-than-3-years',
  },
  {
    label: translate('Texts.compatibility.investment-experience.less-than-5-years'),
    value: 'less-than-5-years',
  },
  {
    label: translate('Texts.compatibility.investment-experience.more-than-5-years'),
    value: 'more-than-5-years',
  },
];

export const applicable = () => [
  {
    label: translate('Texts.compatibility.not-applicable'),
    value: 'not-applicable',
  },
  {
    label: translate('Texts.compatibility.applicable'),
    value: 'applicable',
  },
];

export const prefectures = [
  { label: '', value: '' },
  { label: '北海道', value: '北海道' },
  { label: '青森県', value: '青森県' },
  { label: '岩手県', value: '岩手県' },
  { label: '宮城県', value: '宮城県' },
  { label: '秋田県', value: '秋田県' },
  { label: '山形県', value: '山形県' },
  { label: '福島県', value: '福島県' },
  { label: '茨城県', value: '茨城県' },
  { label: '栃木県', value: '栃木県' },
  { label: '群馬県', value: '群馬県' },
  { label: '埼玉県', value: '埼玉県' },
  { label: '千葉県', value: '千葉県' },
  { label: '東京都', value: '東京都' },
  { label: '神奈川県', value: '神奈川県' },
  { label: '新潟県', value: '新潟県' },
  { label: '富山県', value: '富山県' },
  { label: '石川県', value: '石川県' },
  { label: '福井県', value: '福井県' },
  { label: '山梨県', value: '山梨県' },
  { label: '長野県', value: '長野県' },
  { label: '岐阜県', value: '岐阜県' },
  { label: '静岡県', value: '静岡県' },
  { label: '愛知県', value: '愛知県' },
  { label: '三重県', value: '三重県' },
  { label: '滋賀県', value: '滋賀県' },
  { label: '京都府', value: '京都府' },
  { label: '大阪府', value: '大阪府' },
  { label: '兵庫県', value: '兵庫県' },
  { label: '奈良県', value: '奈良県' },
  { label: '和歌山県', value: '和歌山県' },
  { label: '鳥取県', value: '鳥取県' },
  { label: '島根県', value: '島根県' },
  { label: '岡山県', value: '岡山県' },
  { label: '広島県', value: '広島県' },
  { label: '山口県', value: '山口県' },
  { label: '徳島県', value: '徳島県' },
  { label: '香川県', value: '香川県' },
  { label: '愛媛県', value: '愛媛県' },
  { label: '高知県', value: '高知県' },
  { label: '福岡県', value: '福岡県' },
  { label: '佐賀県', value: '佐賀県' },
  { label: '長崎県', value: '長崎県' },
  { label: '熊本県', value: '熊本県' },
  { label: '大分県', value: '大分県' },
  { label: '宮崎県', value: '宮崎県' },
  { label: '鹿児島県', value: '鹿児島県' },
  { label: '沖縄県', value: '沖縄県' },
];

export const countryCodes = [
  'AF',
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AQ',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BQ',
  'BA',
  'BW',
  'BV',
  'BR',
  'IO',
  'BN',
  'BG',
  'BF',
  'BI',
  'KH',
  'CM',
  'CA',
  'CV',
  'KY',
  'CF',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CG',
  'CD',
  'CK',
  'CR',
  'HR',
  'CU',
  'CW',
  'CY',
  'CZ',
  'CI',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'FR',
  'GF',
  'PF',
  'TF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GG',
  'GN',
  'GW',
  'GY',
  'HT',
  'HM',
  'VA',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IM',
  'IL',
  'IT',
  'JM',
  'JP',
  'JE',
  'JO',
  'KZ',
  'KE',
  'KI',
  'KP',
  'KR',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MK',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'ME',
  'MS',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NU',
  'NF',
  'MP',
  'NO',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'PR',
  'QA',
  'RO',
  'RU',
  'RW',
  'RE',
  'BL',
  'SH',
  'KN',
  'LC',
  'MF',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SX',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'GS',
  'SS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SJ',
  'SZ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TT',
  'TN',
  'TR',
  'TM',
  'TC',
  'TV',
  'UG',
  'UA',
  'AE',
  'GB',
  'US',
  'UM',
  'UY',
  'UZ',
  'VU',
  'VE',
  'VN',
  'VG',
  'VI',
  'WF',
  'EH',
  'YE',
  'ZM',
  'ZW',
  'AX',
];

export const selectOptionSeparator = '----------------';

// Instead of processing the data on every mount and bank code change,
// just have it pre-processed.
export const zenginCodes = Object.values(zenginCode).reduce(
  (acc, bank) => {
    acc.bankCodes.push({
      label: bank.name,
      ruby: [bank.hira, bank.roma],
      value: bank.code,
    });

    acc.branchCodes[bank.code] = Object.values(bank.branches).map(branch => ({
      label: branch.name,
      ruby: [branch.hira, branch.roma],
      value: branch.code,
    }));

    return acc;
  },
  { bankCodes: [], branchCodes: {} } as {
    bankCodes: ComboboxOption[];
    branchCodes: Record<string, ComboboxOption[]>;
  },
);
