import { useSelector } from 'react-redux';
import { RootState } from '../store';
import {
  ApprovalType,
  LoginProviderConfigTypeEnum,
  VariableDtoDashboardModeEnum,
} from '@web-api-client';
import { PATH_ROOT } from '@routes';
import { useMemo } from 'react';

export function useSettingSelector(): RootState['setting'] {
  return useSelector<RootState, RootState['setting']>(state => state.setting);
}

export function useZendeskConfig() {
  const { setting } = useSettingSelector();
  const zendeskConfig = setting?.variables.zendeskConfig;
  return {
    enabled: zendeskConfig && zendeskConfig.enable && !!zendeskConfig.key,
    key: zendeskConfig?.key,
  };
}

export function useSettings() {
  const { setting } = useSettingSelector();

  const dashboardMode = setting?.variables.dashboardMode;
  const loginType = setting?.variables.loginProviderConfig.type;
  const dashboardDisplayFlags = setting?.variables.dashboardDisplayFlags;
  const featureConfigs = setting?.variables.featureConfigs;
  const WALLET_CONNECT_PROJECT_ID = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;

  return useMemo(
    () => ({
      dashboardMode,
      isSSO: loginType === LoginProviderConfigTypeEnum.Sso,
      loginType,
      dashboardDisplayFlags: {
        ...dashboardDisplayFlags,
        enableMenuNavigation: dashboardDisplayFlags?.enableMenuNavigation ?? false,
        enableUserMenu: dashboardDisplayFlags?.enableUserMenu ?? false,
        enableTokensPage: WALLET_CONNECT_PROJECT_ID
          ? dashboardDisplayFlags?.enableTokensPage ?? false
          : false,
        enableLogoNavigation: dashboardDisplayFlags?.enableLogoNavigation ?? false,
      },
      featureConfigs: {
        eKYC: {
          enable: featureConfigs?.eKYC.enable ?? dashboardMode === VariableDtoDashboardModeEnum.Ftk,
        },
        externalSystemIntegration: {
          saisonConnectOAuth: {
            enable: featureConfigs?.externalSystemIntegration.saisonConnectOAuth?.enable ?? false,
          },
        },
        profileUpdate: {
          enable: featureConfigs?.profileUpdate.enable ?? true,
          approvalConfig: {
            requireApproval:
              featureConfigs?.profileUpdate.approvalConfig.requireApproval ??
              dashboardMode === VariableDtoDashboardModeEnum.Ftk,
            approvalType:
              featureConfigs?.profileUpdate.approvalConfig.approvalType ?? ApprovalType.Manual,
          },
        },
        bankUpdate: {
          enable: featureConfigs?.bankUpdate.enable ?? true,
          approvalConfig: {
            requireApproval:
              featureConfigs?.bankUpdate.approvalConfig.requireApproval ??
              dashboardMode === VariableDtoDashboardModeEnum.Ftk,
            approvalType:
              featureConfigs?.bankUpdate.approvalConfig.approvalType ?? ApprovalType.Manual,
          },
        },
        compatibilityUpdate: {
          enable: featureConfigs?.compatibilityUpdate.enable ?? true,
          approvalConfig: {
            requireApproval:
              featureConfigs?.compatibilityUpdate.approvalConfig.requireApproval ??
              dashboardMode === VariableDtoDashboardModeEnum.Ftk,
            approvalType:
              featureConfigs?.compatibilityUpdate.approvalConfig.approvalType ??
              ApprovalType.Manual,
          },
        },
        bankAccountNumberMasking: {
          enable:
            featureConfigs?.bankAccountNumberMasking.enable ??
            dashboardMode === VariableDtoDashboardModeEnum.CbSelfOffering,
        },
      },
      logo: {
        logoUrl: setting?.general.images.logo,
        issuerName: setting?.general.issuerName,
      },
      urls: {
        homeUrl: setting?.variables.homeUrl || PATH_ROOT,
      },
      otherItems: setting?.variables.otherItems || [],
      genderSelectOptions: setting?.variables.genderSelectOptions || [],
    }),
    [setting],
  );
}
