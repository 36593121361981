import React, { PropsWithChildren, useMemo } from 'react';
import { mixPanel } from '@utils/mix-panel';
import UserMenu, { IUserMenuItem } from './UserMenu';
import { useSettings } from '@state/setting/hooks';
import { useHistory } from 'react-router-dom';
import { PATH_AUTH, PATH_USER } from '@routes';

export interface IUserData {
  legacy?: boolean;
}

const UserData = ({ legacy = false }: PropsWithChildren<IUserData>) => {
  const history = useHistory();
  const {
    urls: { homeUrl },
    dashboardDisplayFlags: {
      enableUserMenuSecurity,
      enableUserMenuProfile,
      enableUserMenuBackHome,
    },
  } = useSettings();

  const options: IUserMenuItem[] = useMemo(() => {
    let opts = [
      {
        icon: 'icon-profile',
        onClick: () => {
          mixPanel.track('profile-sub-menu-click');
        },
        path: legacy ? `/#${PATH_USER.profile}` : PATH_USER.profile,
        i18nKey: 'Texts.menu.profile',
        isExternalUrl: false,
      },
      {
        icon: 'icon-password',
        onClick: () => {
          mixPanel.track('security-sub-menu-click');
        },
        path: legacy ? `/#${PATH_USER.security}` : PATH_USER.security,
        i18nKey: 'Texts.menu.security',
        isExternalUrl: false,
      },
      {
        icon: 'icon-home',
        onClick: () => {
          mixPanel.track('security-sub-menu-click');
        },
        path: homeUrl,
        i18nKey: 'Texts.menu.go-home',
        isExternalUrl: true,
      },
      {
        icon: 'icon-log-out',
        onClick: () => {
          history.push(PATH_AUTH.logout);
        },
        // path: legacy ? undefined : '/login',
        i18nKey: 'Texts.menu.log-out',
        isExternalUrl: false,
      },
    ];
    if (!enableUserMenuSecurity) {
      opts = opts.filter(o => o.i18nKey !== 'Texts.menu.security');
    }
    if (!enableUserMenuProfile) {
      opts = opts.filter(item => item.i18nKey !== 'Texts.menu.profile');
    }
    if (!enableUserMenuBackHome) {
      opts = opts.filter(item => item.i18nKey !== 'Texts.menu.go-home');
    }
    return opts;
  }, []);

  return <UserMenu menuItems={options} legacy={legacy} />;
};

export default UserData;
