import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import axios from 'axios';

import 'dayjs/locale/en';
import 'dayjs/locale/ja';

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}`.replace(/\/+$/, '');
const availableLanguagesPath = `${baseUrl}/i18n/available-languages`;
const backendResourceLoadPath = `${baseUrl}/i18n/{lng}`;

const fetchSupportedLanguages = async (): Promise<string[]> => {
  try {
    const res = await axios.get(availableLanguagesPath);
    return res.data;
  } catch (err) {
    return ['ja'];
  }
};

const initI18n = async () => {
  const supportedLngs = await fetchSupportedLanguages();

  i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(HttpBackend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init<HttpBackendOptions>({
      fallbackLng: supportedLngs.includes('ja') ? 'ja' : supportedLngs[0],
      debug: process.env.NODE_ENV === 'development',
      supportedLngs,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        // For compatibility with existing translations
        prefix: '{',
        suffix: '}',
      },
      // Makes sure en-US is resolved as en
      load: 'languageOnly',
      // Gets cookie value from same cookie the LanguageSelect is setting the value to
      detection: {
        caches: [],
        lookupCookie: 'active-language',
        cookieMinutes: 60 * 24 * 31, // 31 days
      },
      // Load and parse from i18n api endpoint
      backend: {
        loadPath: backendResourceLoadPath,
        parse: (data: string) => {
          const parsed = JSON.parse(data) as {
            translations: Record<string, string>;
            language: string;
          };
          return parsed.translations;
        },
      },
    });
};

initI18n();

export default i18n;
