import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { Icon } from '@components/Icon';
import { CCardHeader } from '@coreui/react';
import { Translate } from '../Translate';
import './CardHeader.scss';
import CIcon from '@coreui/icons-react';

export interface ICardHeaderProps {
  title: string | JSX.Element;
  className?: string;
  icon?: string;
  iconContent?: CIcon['content'];
  iconName?: string;
  iconSrc?: string;
  iconClassName?: string;
  iconStyle?: React.CSSProperties;
  onClick?: () => void;
  size?: 'sm' | 'md' | 'lg';
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
}

const CardHeader = ({
  title,
  className,
  icon,
  iconContent,
  iconName,
  iconSrc,
  iconClassName,
  iconStyle,
  onClick,
  children,
  size = 'lg',
  startAdornment,
  endAdornment,
}: PropsWithChildren<ICardHeaderProps>) => {
  return (
    <CCardHeader
      tag='div'
      className={classNames(
        'border-0 CardHeaderComponent',
        {
          ['p-4']: size === 'lg',
          ['p-3']: size === 'md',
          ['p-2']: size === 'sm',
        },
        className,
      )}
      style={onClick && { cursor: 'pointer' }}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      {startAdornment && <div className='start-adornment mr-3'>{startAdornment}</div>}
      <h4
        className={classNames('header-title', {
          ['font-20']: size === 'lg',
          ['font-16']: size === 'md',
          ['font-12']: size === 'sm',
        })}
      >
        <Icon
          className={classNames('mr-3', iconClassName)}
          icon={icon}
          content={iconContent}
          name={iconName}
          src={iconSrc}
          style={iconStyle}
        />
        {typeof title === 'string' ? <Translate translationKey={title} /> : title}
      </h4>
      {children}
      {endAdornment && <div className='end-adornment ml-3'>{endAdornment}</div>}
    </CCardHeader>
  );
};

export default CardHeader;
