import { useSettings } from '@state/setting/hooks';
import i18n from '@utils/translate';

export const useDashboardMode = () => {
  const { dashboardMode } = useSettings();

  const getTranslateByMode = (key: string, params?: Record<string, string>) => {
    // use i18n functionality to use fallback keys
    return i18n.t([`${key}.${dashboardMode}`, key], params);
  };

  return { getTranslateByMode };
};
