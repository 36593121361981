import { configureStore } from '@reduxjs/toolkit';
import type { PreloadedState } from '@reduxjs/toolkit';
import { rootReducer } from './reducer';
import { investorRefresherMiddleware } from './middlewares/investor-middleware';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { translations } from '@securitize/storybook';
import { merge } from 'lodash';

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: {
      name: 'domain-investor-dashboard',
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(investorRefresherMiddleware),
  });
};

let store = setupStore();

export const getStore = () => store;

export const connectTestStore = (
  appStore: AppStore,
  translationOverrides?: Record<string, string>,
) => {
  store = appStore;
  i18next.use(initReactI18next).init({
    lng: 'ja',
    resources: {
      ['ja']: {
        translation: merge({}, translations, translationOverrides),
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      // For compatibility with existing translations
      prefix: '{',
      suffix: '}',
    },
  });
  return appStore;
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export type CommonError = {
  message: string;
  name: string;
  statusCode: number;
};
