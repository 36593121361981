import { PATH_ENTRY, PATH_PRE_ENTRY, PATH_REGISTRATION } from './paths';

export const STEP_TITLES = {
  [PATH_PRE_ENTRY.description]: 'Texts.pre-entry.description.title',
  [PATH_PRE_ENTRY.documents]: 'Texts.pre-entry.documents.title',
  [PATH_PRE_ENTRY.form]: 'Texts.pre-entry.form.title',
  [PATH_PRE_ENTRY.confirm]: 'Texts.pre-entry.confirm.title',
  [PATH_PRE_ENTRY.complete]: 'Texts.pre-entry.complete.title',
  [PATH_ENTRY.prospectus]: 'Texts.entry.prospectus.title',
  [PATH_ENTRY.consent]: 'Texts.entry.consent.title',
  [PATH_ENTRY.confirm]: 'Texts.entry.confirm.title',
  [PATH_ENTRY.complete]: 'Texts.entry.complete.title',
  [PATH_REGISTRATION.termsOfUse]: 'Texts.registration.terms-of-use.title',
  [PATH_REGISTRATION.electronicDelivery]: 'Texts.registration.electronic-delivery.title',
  [PATH_REGISTRATION.connectNetAnswer]: 'Texts.registration.connect-net-answer.title',
  [PATH_REGISTRATION.profile]: 'Texts.registration.profile.title',
  [PATH_REGISTRATION.bank]: 'Texts.registration.bank.title',
  [PATH_REGISTRATION.compatibility]: 'Texts.registration.compatibility.title',
  [PATH_REGISTRATION.otherInformation]: 'Texts.registration.other-information.title',
  [PATH_REGISTRATION.confirm]: 'Texts.registration.confirm.title',
  [PATH_REGISTRATION.kyc]: 'Texts.registration.kyc.title',
  [PATH_REGISTRATION.verifyKycStatus]: 'Texts.registration.verify-kyc-status.title',
};

// Cb only
export const PRE_ENTRY_WIZARD_STEPS = [
  PATH_PRE_ENTRY.description,
  PATH_PRE_ENTRY.documents,
  PATH_PRE_ENTRY.form,
  PATH_PRE_ENTRY.confirm,
  PATH_PRE_ENTRY.complete,
];

// Cb only
export const ENTRY_WIZARD_STEPS = [
  PATH_ENTRY.prospectus,
  PATH_ENTRY.consent,
  PATH_ENTRY.confirm,
  PATH_ENTRY.complete,
];

export const REGISTRATION_WIZARD_STEPS = [
  PATH_REGISTRATION.termsOfUse,
  PATH_REGISTRATION.electronicDelivery,
  PATH_REGISTRATION.connectNetAnswer,
  PATH_REGISTRATION.profile,
  PATH_REGISTRATION.bank,
  PATH_REGISTRATION.compatibility,
  PATH_REGISTRATION.otherInformation,
  PATH_REGISTRATION.confirm,
  PATH_REGISTRATION.kyc,
  PATH_REGISTRATION.verifyKycStatus,
];

export enum WizardType {
  Registration,
  PreEntry,
  Entry,
}
