import { Next } from '../next';

export const updateGeneralInfoNext = new Next(
  '/investor-update-requests/profile',
  'GENERAL_UPDATE',
  {
    method: 'POST',
  },
);

export const updateBankAccountNext = new Next('/investor-update-requests/bank', 'BANK_UPDATE', {
  method: 'POST',
});

export const updateCompatibilityNext = new Next(
  '/investor-update-requests/compatibility',
  'COMPATIBILITY_UPDATE',
  {
    method: 'POST',
  },
);

export const SET_PROFILE_MODAL_OPEN = 'SET_PROFILE_MODAL_OPEN';
export const SET_BANK_MODAL_OPEN = 'SET_BANK_MODAL_OPEN';
export const SET_COMPATIBILITY_MODAL_OPEN = 'SET_COMPATIBILITY_MODAL_OPEN';
export const SET_OTHER_ITEMS_MODAL_OPEN = 'SET_OTHER_ITEMS_MODAL_OPEN';

export const updatePhoneNumberNext = new Next(`/investors/phone`, 'UPDATE_PHONE_NUMBER', {
  method: 'PATCH',
});
