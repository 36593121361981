/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslate } from '@utils/translate';

// dont make one liner, \n doesnt work as new line with white-space:pre, this does
const newLine = `
`;
const replaceWithNewLine = (string: string) => string.replace(/\\n|<br>|<br\/>>|<br \/>/g, newLine);

const markupBold = (translation: string) => {
  const parts = translation.split(/<b>|<\/b>|<\/ b>/);
  if (parts.length > 1) {
    return parts.reduce((res: any, part, index) => {
      if (index % 2) {
        res.push(<strong key={`part-${res.length}`}>{replaceWithNewLine(part)}</strong>);
      } else {
        res.push(replaceWithNewLine(part));
      }
      return res;
    }, []);
  }
  return replaceWithNewLine(translation);
};

export type TranslateProps = {
  translationKey: string;
  parameters?: any;
};

export const Translate = (props: TranslateProps) => {
  const { translate } = useTranslate();
  const { translationKey, parameters = {} } = props;
  return <>{markupBold(translate(translationKey, parameters))}</>;
};
