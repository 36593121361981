import { createHttpAction, IOptions } from '../http-action';
import {
  getInvestorNext,
  startKycNext,
  updateInvestorNext,
  updateKycStatusNext,
  uploadElectronicDeliveryNext,
  uploadTermsOfUseNext,
  getInvestorDocumentsNext,
  getOpportunityListOfTokenDocumentsNext,
  getAddressCompleteNext,
  createEposnetAccessTokenNext,
  getEposnetUserInfoNext,
  SET_HAS_ISSUED_OAUTH_ACCESS_TOKEN,
  getReadOpportunityDocumentsNext,
  getOpportunityDocumentsNext,
  getDocumentNext,
  getDomainDocumentsNext,
  getAnnualTransactionReportsNext,
  getInvestorVerifiedTokensNext,
  verifyInvestorTokenNext,
  updateInvestorOtherItemsNext,
} from './actionTypes';
import {
  GetDocument,
  IAddressCompleteRequestDto,
  IGetOpportunityDocumentsQueryDto,
  IGetReadOpportunityDocuments,
  IInvestorDocumentRequestDto,
} from './models';
import {
  CreateEposnetAccessTokenBodyDto,
  Document,
  UpdateKycStatusDto,
  UpdateOtherItemsDto,
  UpdateProfileDto,
} from '@web-api-client';

export const getInvestor = (option?: IOptions<void>) => {
  return createHttpAction(getInvestorNext, {}, option);
};

export const updateInvestor = (body: UpdateProfileDto, option?: IOptions<void>) => {
  return createHttpAction(updateInvestorNext, { body }, option);
};

export const updateInvestorOtherItems = (body: UpdateOtherItemsDto, option?: IOptions<void>) => {
  return createHttpAction(updateInvestorOtherItemsNext, { body }, option);
};

export const getInvestorDocuments = (query?: IInvestorDocumentRequestDto) => {
  return createHttpAction(getInvestorDocumentsNext, { query });
};

export const startKyc = (option?: IOptions<void>) => {
  return createHttpAction(startKycNext, {}, option);
};

export const updateKycStatus = (body: UpdateKycStatusDto, option?: IOptions<void>) => {
  return createHttpAction(updateKycStatusNext, { body }, option);
};

export const uploadTermsOfUse = (option?: IOptions<void>) =>
  createHttpAction(uploadTermsOfUseNext, {}, option);

export const uploadElectronicDelivery = (option?: IOptions<void>) =>
  createHttpAction(uploadElectronicDeliveryNext, {}, option);

export const getOpportunityListOfTokenDocuments = () =>
  createHttpAction(getOpportunityListOfTokenDocumentsNext, {});

export const getAddressComplete = (query: IAddressCompleteRequestDto) => {
  return createHttpAction(getAddressCompleteNext, { query });
};

export const createEposnetAccessToken = (
  body: CreateEposnetAccessTokenBodyDto,
  option?: IOptions<void>,
) => {
  return createHttpAction(createEposnetAccessTokenNext, { body }, option);
};

export const getEposnetUserInfo = (option?: IOptions<void>) => {
  return createHttpAction(getEposnetUserInfoNext, {}, option);
};

export const setHasIssuedOAuthAccessToken = (hasIssued: boolean) => ({
  type: SET_HAS_ISSUED_OAUTH_ACCESS_TOKEN,
  payload: hasIssued,
});

export const getOpportunityDocuments = (query?: IGetOpportunityDocumentsQueryDto) => {
  return createHttpAction(getOpportunityDocumentsNext, { query });
};

export const getDomainDocuments = () => {
  return createHttpAction(getDomainDocumentsNext, {});
};

export const getAnnualTransactionReports = () => {
  return createHttpAction(getAnnualTransactionReportsNext, {});
};

export const getReadOpportunityDocuments = (query?: IGetReadOpportunityDocuments) => {
  return createHttpAction(getReadOpportunityDocumentsNext, { query });
};

export const getDocument = (query: GetDocument, option?: IOptions<Document>) => {
  if ('opportunityId' in query) {
    const { documentId, opportunityId, tokenId } = query;
    return createHttpAction(
      getDocumentNext,
      { params: { documentId }, query: { opportunityId, tokenId, type: 'opportunity' } },
      option,
    );
  } else {
    const { documentId } = query;
    return createHttpAction(
      getDocumentNext,
      { params: { documentId }, query: { type: 'investor' } },
      option,
    );
  }
};

export const getInvestorVerifiedTokens = (option?: IOptions<void>) => {
  return createHttpAction(getInvestorVerifiedTokensNext, {}, option);
};

export const verifyInvestorToken = (
  tokenId: string,
  readDocumentIds?: number[],
  options?: IOptions<void>,
) => {
  return createHttpAction(
    verifyInvestorTokenNext,
    { body: { tokenId, documentIds: readDocumentIds } },
    options,
  );
};
