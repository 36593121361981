import {
  loginNext,
  loginSonyNext,
  refreshSessionNext,
  resetPasswordNext,
} from '@state/auth/actionTypes';
import { getInvestor, getInvestorDocuments } from '@state/investor/actions';
import { activateAccountNext } from '@state/registration/actionTypes';
import { RootState } from '@state/store';
import { Middleware } from 'redux';

// eslint-disable-next-line @typescript-eslint/ban-types
export const investorRefresherMiddleware: Middleware<{}, RootState> = store => next => action => {
  if (
    // All of these actions set isAuthenticated to true
    [
      loginNext.SUCCESS,
      refreshSessionNext.SUCCESS,
      activateAccountNext.SUCCESS,
      resetPasswordNext.SUCCESS,
      loginSonyNext.SUCCESS,
    ].includes(action.type)
  ) {
    store.dispatch(getInvestor());
    store.dispatch(getInvestorDocuments());
  }
  return next(action);
};
