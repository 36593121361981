import { IAuthState, auth } from './auth/reducer';
import { IOpportunitiesState, opportunities } from './opportunities/reducer';
import { IQuestionsState, questions } from './questions/reducer';
import { IOpportunityState, opportunityDetail } from './opportunityDetail/reducer';
import { ISettingState, setting } from './setting/reducer';
import { IRegistrationState, registration } from './registration/reducer';
import { ISecurityPageState, security } from './security/reducer';
import { IProfileState, profile } from './profile/reducer';
import { IInvestorState, investor } from './investor/reducer';
import { IPortfolioState, portfolio } from './portfolio/reducer';
import { IPortfolioDetailState, portfolioDetail } from './portfolioDetail/reducer';
import { IInvestmentState, investment } from './investment/reducer';
import { IWizardState, wizard } from './wizard/reducer';
import { AnyAction, CombinedState, combineReducers } from '@reduxjs/toolkit';
import { IWalletsState, wallets } from './wallets/reducer';
import { IProvidersState, providers } from './providers/reducer';
import { ITokenWalletsState, tokenWallets } from './tokenWallets/reducer';
import {
  ITradeBuyOpportunities,
  ITradeSellOpportunities,
  tradeBuyOpportunities,
  tradeSellOpportunities,
} from './trade-opportunities/reducer';
import {
  IExternalSystemIntegrationsState,
  integrations,
} from './external-system-integrations/reducer';

type State = CombinedState<{
  auth: IAuthState;
  opportunities: IOpportunitiesState;
  questions: IQuestionsState;
  opportunityDetail: IOpportunityState;
  profile: IProfileState;
  setting: ISettingState;
  security: ISecurityPageState;
  registration: IRegistrationState;
  investor: IInvestorState;
  portfolio: IPortfolioState;
  portfolioDetail: IPortfolioDetailState;
  investment: IInvestmentState;
  wizard: IWizardState;
  wallets: IWalletsState;
  tokenWallets: ITokenWalletsState;
  providers: IProvidersState;
  tradeSellOpportunities: ITradeSellOpportunities;
  tradeBuyOpportunities: ITradeBuyOpportunities;
  integrations: IExternalSystemIntegrationsState;
}>;

const combinedReducer = combineReducers({
  auth,
  opportunities,
  questions,
  opportunityDetail,
  profile,
  setting,
  security,
  registration,
  investor,
  portfolio,
  portfolioDetail,
  investment,
  wizard,
  wallets,
  tokenWallets,
  providers,
  tradeBuyOpportunities,
  tradeSellOpportunities,
  integrations,
});

export const rootReducer = (state: State | undefined, action: AnyAction) =>
  combinedReducer(state, action);
