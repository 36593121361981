export enum HttpCommonErrorTypes {
  RECAPTCHA_INVALID = 'RECAPTCHA_INVALID',
  INVESTOR_ALREADY_EXISTS = 'INVESTOR_ALREADY_EXISTS',
  INVESTOR_UPDATE_REQUEST_ALREADY_EXISTS = 'INVESTOR_UPDATE_REQUEST_ALREADY_EXISTS',
  INVESTOR_NOT_VERIFIED = 'INVESTOR_NOT_VERIFIED',
  INVESTMENT_ALREADY_EXISTS = 'INVESTMENT_ALREADY_EXISTS',
  UNAUTHORIZED_EXCEPTION = 'UNAUTHORIZED_EXCEPTION',
  NOT_FOUND_EXCEPTION = 'NOT_FOUND_EXCEPTION',
  NOT_FOUND_INVESTMENT = 'NOT_FOUND_INVESTMENT',
  SAISON_FAMILY_MEMBER_ERROR = 'SAISON_FAMILY_MEMBER_ERROR',
}
