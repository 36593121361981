import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import dayjs from 'dayjs';
import Cookies from 'js-cookie';

export function setActiveLanguage(lng: string) {
  Cookies.set('active-language', lng, { expires: 31, path: '/' });
  document.documentElement.lang = lng;
  dayjs.locale(lng);
  i18n.changeLanguage(lng);
}

export function translate(key: string, params?: Record<string, string | React.ReactNode>) {
  return i18n.t(key, params);
}

export function useTranslate() {
  const { t } = useTranslation();

  function translate(key: string | string[], params?: Record<string, unknown>) {
    return t(key, params);
  }

  return { translate };
}

export default i18n;
