import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { GetDomainSettingResponseDto } from '@web-api-client';
import { IErrorResponse } from '../types';
import { getSettingNext } from './actionTypes';

export interface ISettingState {
  readonly isLoading: boolean;
  readonly isError: boolean;
  readonly setting?: GetDomainSettingResponseDto;
  readonly error: IErrorResponse | null;
}

export const initialState: ISettingState = {
  isLoading: true,
  isError: false,
  error: null,
  setting: undefined,
};

export const setting = createReducer(initialState, builder =>
  builder
    .addCase<string, PayloadAction<void>>(getSettingNext.INIT, state => {
      state.isLoading = true;
      state.isError = false;
    })
    .addCase<string, PayloadAction<GetDomainSettingResponseDto>>(
      getSettingNext.SUCCESS,
      (state, { payload: setting }) => {
        state.setting = setting;
        state.isLoading = false;
      },
    )
    .addCase<string, PayloadAction<IErrorResponse>>(getSettingNext.ERROR, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = payload;
    }),
);
