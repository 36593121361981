import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  createEposnetAccessTokenNext,
  getAddressCompleteNext,
  getInvestorDocumentsNext,
  getEposnetUserInfoNext,
  getInvestorNext,
  getOpportunityListOfTokenDocumentsNext,
  SET_HAS_ISSUED_OAUTH_ACCESS_TOKEN,
  startKycNext,
  updateInvestorNext,
  updateKycStatusNext,
  uploadElectronicDeliveryNext,
  uploadTermsOfUseNext,
  getOpportunityDocumentsNext,
  getReadOpportunityDocumentsNext,
  getDomainDocumentsNext,
  getDocumentNext,
  getAnnualTransactionReportsNext,
  getInvestorVerifiedTokensNext,
  verifyInvestorTokenNext,
  updateInvestorOtherItemsNext,
} from './actionTypes';
import { InvestorErrorTypes, IStartKycResult } from './models';
import { HttpCommonErrorTypes } from '@utils/error-types';
import { IHttpErrorResponse } from '../types';
import {
  AdditionalStatusesKycStatusEnum,
  AddressCompleteResponseDto,
  Document,
  GetEposnetUserInfoResponseDto,
  MappedDomainInvestorResponseDto,
  OpportunityListItemDto,
  RegistrationStatuses,
  RegistrationStatusesBankRegistrationStatusEnum,
  RegistrationStatusesCompatibilityRegistrationStatusEnum,
  RegistrationStatusesGeneralRegistrationStatusEnum,
  ReadOpportunityDocument,
} from '@web-api-client';
import { saisonConnectConnectNext } from '@state/external-system-integrations/actionTypes';

type IInvestorErrorResponse = IHttpErrorResponse<HttpCommonErrorTypes | InvestorErrorTypes | null>;

export interface IInvestorState {
  readonly isLoading: boolean;
  readonly isError: boolean;

  readonly isUploadingInvestor: boolean;
  readonly isUploadingInvestorError: boolean;

  readonly isUploadingInvestorOtherItems: boolean;
  readonly isUploadingInvestorOtherItemsError: boolean;

  readonly isUploadingDocuments: boolean;
  readonly isUploadingDocumentsError: boolean;

  readonly isLoadingDocuments: boolean;
  readonly isLoadingDocumentsError: boolean;

  readonly isLoadingOpportunityListOfTokenDocuments: boolean;
  readonly isLoadingOpportunityListOfTokenDocumentsError: boolean;

  readonly isLoadingUpdateKycStatus: boolean;
  readonly isLoadingUpdateKycStatusError: boolean;

  readonly isLoadingAddressComplete: boolean;

  readonly isRevertedRegistration: boolean;

  readonly isLoadingBankProviderOAuth: boolean;
  readonly isLoadingBankProviderOAuthError: boolean;
  readonly hasIssuedOAuthAccessToken: boolean;

  readonly investor: MappedDomainInvestorResponseDto | null;

  readonly documents: Document[] | null;
  readonly opportunityListOfTokenDocuments: OpportunityListItemDto[];

  readonly opportunityDocuments: Document[];
  readonly isLoadingOpportunityDocuments: boolean;
  readonly isLoadingOpportunityDocumentsError: boolean;

  readonly documentUrlMap: Record<string | number, Pick<Document, 'url' | 'expiresDate'>>;

  readonly domainDocuments: Document[] | null;
  readonly isLoadingDomainDocuments: boolean;
  readonly isLoadingDomainDocumentsError: boolean;

  readonly annualTransactionReports: Document[] | null;
  readonly isLoadingAnnualTransactionReports: boolean;
  readonly isLoadingAnnualTransactionReportsError: boolean;

  readonly readOpportunityDocuments: ReadOpportunityDocument[] | null;
  readonly isLoadingReadOpportunityDocuments: boolean;
  readonly isLoadingReadOpportunityDocumentsError: boolean;

  readonly startKycResult: IStartKycResult | null;
  readonly error: IInvestorErrorResponse | null;

  readonly addressComplete: AddressCompleteResponseDto | null;

  readonly oauthBankResult: GetEposnetUserInfoResponseDto | null;

  readonly investorVerifiedTokens: string[];
  readonly isInvestorVerifiedTokensLoading: boolean;
  readonly isVerifyInvestorTokenLoading: boolean;
}

export const initialState: IInvestorState = {
  isLoading: false,
  isError: false,

  isUploadingInvestor: false,
  isUploadingInvestorError: false,

  isUploadingInvestorOtherItems: false,
  isUploadingInvestorOtherItemsError: false,

  isUploadingDocuments: false,
  isUploadingDocumentsError: false,

  isLoadingDocuments: false,
  isLoadingDocumentsError: false,

  isLoadingOpportunityListOfTokenDocuments: false,
  isLoadingOpportunityListOfTokenDocumentsError: false,

  isLoadingUpdateKycStatus: false,
  isLoadingUpdateKycStatusError: false,

  isLoadingAddressComplete: false,

  isRevertedRegistration: false,

  isLoadingBankProviderOAuth: false,
  isLoadingBankProviderOAuthError: false,
  hasIssuedOAuthAccessToken: false,

  investor: null,

  documents: null,
  opportunityListOfTokenDocuments: [],

  opportunityDocuments: [],
  isLoadingOpportunityDocuments: false,
  isLoadingOpportunityDocumentsError: false,

  documentUrlMap: {},

  domainDocuments: [],
  isLoadingDomainDocuments: false,
  isLoadingDomainDocumentsError: false,

  annualTransactionReports: [],
  isLoadingAnnualTransactionReports: false,
  isLoadingAnnualTransactionReportsError: false,

  readOpportunityDocuments: [],
  isLoadingReadOpportunityDocuments: false,
  isLoadingReadOpportunityDocumentsError: false,

  startKycResult: null,
  error: null,

  addressComplete: null,

  oauthBankResult: null,

  investorVerifiedTokens: [],
  isInvestorVerifiedTokensLoading: false,
  isVerifyInvestorTokenLoading: false,
};

const getIsRevertedRegistration = (
  registrationStatuses?: RegistrationStatuses,
  kycStatus?: AdditionalStatusesKycStatusEnum,
) => {
  if (!registrationStatuses) return false;
  const { generalRegistrationStatus, bankRegistrationStatus, compatibilityRegistrationStatus } =
    registrationStatuses;
  return [generalRegistrationStatus, bankRegistrationStatus, compatibilityRegistrationStatus]
    .map(status => {
      if (kycStatus === AdditionalStatusesKycStatusEnum.Reverted) {
        return true;
      }
      switch (status) {
        case RegistrationStatusesGeneralRegistrationStatusEnum.Reverted ||
          RegistrationStatusesBankRegistrationStatusEnum.Reverted ||
          RegistrationStatusesCompatibilityRegistrationStatusEnum.Reverted:
          return true;
        default:
          return false;
      }
    })
    .some(isReverted => isReverted);
};

export const investor = createReducer(initialState, builder =>
  builder
    .addCase<string, PayloadAction<void>>(getInvestorNext.INIT, state => {
      state.isLoading = true;
      state.isError = false;
    })
    .addCase<string, PayloadAction<IInvestorErrorResponse>>(
      getInvestorNext.ERROR,
      (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.error = payload;
      },
    )
    .addCase<string, PayloadAction<MappedDomainInvestorResponseDto>>(
      getInvestorNext.SUCCESS,
      (state, { payload }) => ({
        ...state,
        isLoading: false,
        isError: false,
        investor: payload,
        isRevertedRegistration: getIsRevertedRegistration(
          payload.registrationStatuses,
          payload.additionalStatuses?.kycStatus,
        ),
      }),
    )

    .addCase<string, PayloadAction<void>>(updateInvestorNext.INIT, state => {
      state.isUploadingInvestor = true;
      state.isUploadingInvestorError = false;
    })
    .addCase<string, PayloadAction<void>>(updateInvestorNext.SUCCESS, state => {
      state.isUploadingInvestor = false;
    })
    .addCase<string, PayloadAction<void>>(updateInvestorNext.ERROR, state => {
      state.isUploadingInvestor = false;
      state.isUploadingInvestorError = true;
    })
    .addCase<string, PayloadAction<void>>(updateInvestorOtherItemsNext.INIT, state => {
      state.isUploadingInvestorOtherItems = true;
      state.isUploadingInvestorOtherItemsError = false;
    })
    .addCase<string, PayloadAction<void>>(updateInvestorOtherItemsNext.SUCCESS, state => {
      state.isUploadingInvestorOtherItems = false;
    })
    .addCase<string, PayloadAction<void>>(updateInvestorOtherItemsNext.ERROR, state => {
      state.isUploadingInvestorOtherItems = false;
      state.isUploadingInvestorOtherItemsError = true;
    })

    .addCase<string, PayloadAction<void>>(getInvestorDocumentsNext.INIT, state => {
      state.isLoadingDocuments = true;
      state.isLoadingDocumentsError = false;
    })
    .addCase<string, PayloadAction<Document[]>>(
      getInvestorDocumentsNext.SUCCESS,
      (state, { payload }) => ({
        ...state,
        isLoadingDocuments: false,
        isLoadingDocumentsError: false,
        documents: payload,
      }),
    )
    .addCase<string, PayloadAction<void>>(getInvestorDocumentsNext.ERROR, state => {
      state.isLoadingDocuments = false;
      state.isLoadingDocumentsError = true;
    })
    .addCase<string, PayloadAction<OpportunityListItemDto[]>>(
      getOpportunityListOfTokenDocumentsNext.INIT,
      state => ({
        ...state,
        isLoadingOpportunityListOfTokenDocuments: true,
        isLoadingOpportunityListOfTokenDocumentsError: false,
        isLoadingOpportunityDocumentsError: false,
        isLoadingDocumentsError: false,
      }),
    )
    .addCase<string, PayloadAction<OpportunityListItemDto[]>>(
      getOpportunityListOfTokenDocumentsNext.ERROR,
      state => ({
        ...state,
        isLoadingOpportunityListOfTokenDocuments: false,
        isLoadingOpportunityListOfTokenDocumentsError: true,
      }),
    )
    .addCase<string, PayloadAction<OpportunityListItemDto[]>>(
      getOpportunityListOfTokenDocumentsNext.SUCCESS,
      (state, { payload }) => {
        return {
          ...state,
          opportunityListOfTokenDocuments: payload,
          isLoadingOpportunityListOfTokenDocuments: false,
          isLoadingOpportunityListOfTokenDocumentsError: false,
        };
      },
    )
    .addCase<string, PayloadAction<void>>(uploadTermsOfUseNext.INIT, state => {
      state.isUploadingDocuments = true;
      state.isUploadingDocumentsError = false;
    })
    .addCase<string, PayloadAction<Document[]>>(
      uploadTermsOfUseNext.SUCCESS,
      (state, { payload }) => ({
        ...state,
        isUploadingDocuments: false,
        isUploadingDocumentsError: false,
        documents: payload,
      }),
    )
    .addCase<string, PayloadAction<void>>(uploadTermsOfUseNext.ERROR, state => {
      state.isError = true;
      state.isUploadingDocuments = false;
      state.isUploadingDocumentsError = true;
    })

    .addCase<string, PayloadAction<void>>(uploadElectronicDeliveryNext.INIT, state => {
      state.isUploadingDocuments = true;
      state.isUploadingDocumentsError = false;
    })
    .addCase<string, PayloadAction<Document[]>>(
      uploadElectronicDeliveryNext.SUCCESS,
      (state, { payload }) => ({
        ...state,
        isUploadingDocuments: false,
        isUploadingDocumentsError: false,
        documents: payload,
      }),
    )
    .addCase<string, PayloadAction<void>>(uploadElectronicDeliveryNext.ERROR, state => {
      state.isError = true;
      state.isUploadingDocuments = false;
      state.isUploadingDocumentsError = true;
    })
    .addCase<string, PayloadAction<void>>(updateKycStatusNext.INIT, state => ({
      ...state,
      isLoadingUpdateKycStatus: true,
      isLoadingUpdateKycStatusError: false,
      error: null,
    }))
    .addCase<string, PayloadAction<void>>(updateKycStatusNext.SUCCESS, state => ({
      ...state,
      isLoadingUpdateKycStatus: false,
      isLoadingUpdateKycStatusError: false,
      error: null,
    }))
    .addCase<string, PayloadAction<IInvestorErrorResponse>>(
      updateKycStatusNext.ERROR,
      (state, { payload }) => ({
        ...state,
        isLoadingUpdateKycStatus: false,
        isLoadingUpdateKycStatusError: true,
        error: payload,
      }),
    )
    .addCase<string, PayloadAction<IStartKycResult>>(
      startKycNext.SUCCESS,
      (state, { payload }) => ({
        ...state,
        startKycResult: payload,
        isLoading: false,
        isError: false,
        error: null,
      }),
    )
    .addCase<string, PayloadAction<void>>(getAddressCompleteNext.INIT, state => ({
      ...state,
      isLoadingAddressComplete: true,
    }))
    .addCase<string, PayloadAction<AddressCompleteResponseDto>>(
      getAddressCompleteNext.SUCCESS,
      (state, { payload }) => {
        const res = {
          ...state,
          isLoadingAddressComplete: false,
        };
        if (Object.keys(payload).length > 0) {
          res.addressComplete = payload;
        }
        return res;
      },
    )
    .addCase<string, PayloadAction<boolean>>(
      SET_HAS_ISSUED_OAUTH_ACCESS_TOKEN,
      (state, { payload }) => ({
        ...state,
        hasIssuedOAuthAccessToken: payload,
      }),
    )
    .addCase<string, PayloadAction<void>>(createEposnetAccessTokenNext.INIT, state => ({
      ...state,
      isLoadingBankProviderOAuth: true,
      isLoadingBankProviderOAuthError: false,
    }))
    .addCase<string, PayloadAction<void>>(createEposnetAccessTokenNext.SUCCESS, state => ({
      ...state,
      isLoadingBankProviderOAuth: false,
      isLoadingBankProviderOAuthError: false,
      hasIssuedOAuthAccessToken: true,
    }))
    .addCase<string, PayloadAction<IInvestorErrorResponse>>(
      createEposnetAccessTokenNext.ERROR,
      state => ({
        ...state,
        isLoadingBankProviderOAuth: false,
        isLoadingBankProviderOAuthError: true,
      }),
    )
    .addCase<string, PayloadAction<void>>(getEposnetUserInfoNext.INIT, state => ({
      ...state,
      isLoadingBankProviderOAuth: true,
      isLoadingBankProviderOAuthError: false,
      error: null,
    }))
    .addCase<string, PayloadAction<GetEposnetUserInfoResponseDto>>(
      getEposnetUserInfoNext.SUCCESS,
      (state, { payload }) => {
        return {
          ...state,
          isLoadingBankProviderOAuth: false,
          isLoadingBankProviderOAuthError: false,
          oauthBankResult: payload,
        };
      },
    )
    .addCase<string, PayloadAction<IInvestorErrorResponse>>(
      getEposnetUserInfoNext.ERROR,
      (state, { payload }) => ({
        ...state,
        isLoadingBankProviderOAuth: false,
        isLoadingBankProviderOAuthError: true,
        error: payload,
      }),
    )
    .addCase<string, PayloadAction<void>>(getOpportunityDocumentsNext.INIT, state => {
      state.isLoadingOpportunityDocuments = true;
      state.isLoadingOpportunityDocumentsError = false;
      state.documentUrlMap = {};
    })
    .addCase<string, PayloadAction<Document[]>>(
      getOpportunityDocumentsNext.SUCCESS,
      (state, { payload }) => ({
        ...state,
        isLoadingOpportunityDocuments: false,
        isLoadingOpportunityDocumentsError: false,
        opportunityDocuments: payload,
      }),
    )
    .addCase<string, PayloadAction<void>>(getOpportunityDocumentsNext.ERROR, state => {
      state.isLoadingOpportunityDocuments = false;
      state.isLoadingOpportunityDocumentsError = true;
    })
    .addCase<string, PayloadAction<Required<Document>>>(
      getDocumentNext.SUCCESS,
      (state, { payload }) => {
        if (payload.opportunityDocumentId) {
          state.documentUrlMap[payload.opportunityDocumentId] = {
            url: payload.url,
            expiresDate: payload.expiresDate,
          };
        }
        if (payload.investorDocumentId) {
          state.documentUrlMap[payload.investorDocumentId] = {
            url: payload.url,
            expiresDate: payload.expiresDate,
          };
        }
      },
    )
    .addCase<string, PayloadAction<void>>(getDomainDocumentsNext.INIT, state => {
      state.isLoadingDomainDocuments = true;
      state.isLoadingDomainDocumentsError = false;
    })
    .addCase<string, PayloadAction<Document[]>>(
      getDomainDocumentsNext.SUCCESS,
      (state, { payload }) => ({
        ...state,
        isLoadingDomainDocuments: false,
        isLoadingDomainDocumentsError: false,
        domainDocuments: payload,
      }),
    )
    .addCase<string, PayloadAction<void>>(getDomainDocumentsNext.ERROR, state => {
      state.isLoadingDomainDocuments = false;
      state.isLoadingDomainDocumentsError = true;
    })
    .addCase<string, PayloadAction<void>>(getAnnualTransactionReportsNext.INIT, state => {
      state.isLoadingAnnualTransactionReports = true;
      state.isLoadingAnnualTransactionReportsError = false;
    })
    .addCase<string, PayloadAction<Document[]>>(
      getAnnualTransactionReportsNext.SUCCESS,
      (state, { payload }) => ({
        ...state,
        isLoadingAnnualTransactionReports: false,
        isLoadingAnnualTransactionReportsError: false,
        annualTransactionReports: payload,
      }),
    )
    .addCase<string, PayloadAction<void>>(getAnnualTransactionReportsNext.ERROR, state => {
      state.isLoadingAnnualTransactionReports = false;
      state.isLoadingAnnualTransactionReportsError = true;
    })
    .addCase<string, PayloadAction<void>>(getReadOpportunityDocumentsNext.INIT, state => {
      state.isLoadingReadOpportunityDocuments = true;
      state.isLoadingReadOpportunityDocumentsError = false;
    })
    .addCase<string, PayloadAction<ReadOpportunityDocument[]>>(
      getReadOpportunityDocumentsNext.SUCCESS,
      (state, { payload }) => ({
        ...state,
        isLoadingReadOpportunityDocuments: false,
        isLoadingReadOpportunityDocumentsError: false,
        readOpportunityDocuments: payload,
      }),
    )
    .addCase<string, PayloadAction<void>>(getReadOpportunityDocumentsNext.ERROR, state => {
      state.isLoadingReadOpportunityDocuments = false;
      state.isLoadingReadOpportunityDocumentsError = true;
    })
    .addCase<string, PayloadAction<void>>(getInvestorVerifiedTokensNext.INIT, state => {
      state.isInvestorVerifiedTokensLoading = true;
    })
    .addCase<string, PayloadAction<string[]>>(
      getInvestorVerifiedTokensNext.SUCCESS,
      (state, { payload }) => {
        state.isInvestorVerifiedTokensLoading = false;
        state.investorVerifiedTokens = payload;
      },
    )
    .addCase<string, PayloadAction<void>>(getInvestorVerifiedTokensNext.ERROR, state => {
      state.isInvestorVerifiedTokensLoading = false;
    })
    .addCase<string, PayloadAction<void>>(verifyInvestorTokenNext.INIT, state => {
      state.isVerifyInvestorTokenLoading = true;
    })
    .addCase<string, PayloadAction<string[]>>(verifyInvestorTokenNext.SUCCESS, state => {
      state.isVerifyInvestorTokenLoading = false;
    })
    .addCase<string, PayloadAction<void>>(verifyInvestorTokenNext.ERROR, state => {
      state.isVerifyInvestorTokenLoading = false;
    })
    .addMatcher<PayloadAction<void>>(
      ({ type }) =>
        [updateInvestorNext.INIT, updateInvestorOtherItemsNext.INIT, startKycNext.INIT].includes(
          type,
        ),
      state => ({
        ...state,
        isError: false,
        startKycResult: null,
        error: null,
      }),
    )
    .addMatcher<PayloadAction<MappedDomainInvestorResponseDto>>(
      ({ type }) =>
        [
          updateInvestorNext.SUCCESS,
          updateInvestorOtherItemsNext.SUCCESS,
          saisonConnectConnectNext.SUCCESS,
        ].includes(type),
      (state, { payload }) => ({
        ...state,
        investor: payload,
        isLoading: false,
        isError: false,
        error: null,
      }),
    )
    .addMatcher<PayloadAction<IInvestorErrorResponse>>(
      ({ type }) =>
        [updateInvestorNext.ERROR, updateInvestorOtherItemsNext.ERROR, startKycNext.ERROR].includes(
          type,
        ),
      (state, { payload }) => ({
        ...state,
        isLoading: false,
        isError: true,
        error: payload,
      }),
    )
    .addMatcher<PayloadAction<void>>(
      ({ type }) => [updateInvestorNext.SUCCESS].includes(type),
      state => {
        if (
          !state.isUploadingInvestor &&
          !state.isUploadingInvestorError &&
          !state.isUploadingDocuments &&
          !state.isUploadingDocumentsError
        ) {
          return {
            ...state,
            isUploading: false,
            isUploadingError: false,
          };
        }
      },
    ),
);
