import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { translate } from '@utils/translate';
import { errorToast, successToast } from '@components';
import { HttpCommonErrorTypes } from '@utils/error-types';
import { IHttpErrorResponse } from '../types';
import { updateInvestorNext, updateInvestorOtherItemsNext } from '../investor/actionTypes';
import {
  SET_BANK_MODAL_OPEN,
  SET_COMPATIBILITY_MODAL_OPEN,
  SET_OTHER_ITEMS_MODAL_OPEN,
  SET_PROFILE_MODAL_OPEN,
  updateBankAccountNext,
  updateCompatibilityNext,
  updateGeneralInfoNext,
  updatePhoneNumberNext,
} from './actionTypes';

export interface IProfileState {
  readonly isLoading: boolean;
  readonly isError: boolean;
  readonly isProfileModalOpen: boolean;
  readonly isBankModalOpen: boolean;
  readonly isCompatibilityModalOpen: boolean;
  readonly isOtherItemsModalOpen: boolean;
}

export const initialState: IProfileState = {
  isError: false,
  isLoading: false,
  isProfileModalOpen: false,
  isBankModalOpen: false,
  isCompatibilityModalOpen: false,
  isOtherItemsModalOpen: false,
};

export const profile = createReducer(initialState, builder =>
  builder
    .addCase<string, PayloadAction<void>>(updateGeneralInfoNext.INIT, state => ({
      ...state,
      isLoading: true,
      isError: false,
    }))
    .addCase<string, PayloadAction<IHttpErrorResponse<HttpCommonErrorTypes>>>(
      updateGeneralInfoNext.ERROR,
      (state, { payload }) => {
        if (payload.name === HttpCommonErrorTypes.INVESTOR_UPDATE_REQUEST_ALREADY_EXISTS) {
          window.setTimeout(() =>
            errorToast(
              translate('Errors.already-requested', {
                data: translate('Texts.profile.update', {
                  item: translate('Texts.profile.general-information'),
                }),
              }),
            ),
          );
        }
        if (payload.name === HttpCommonErrorTypes.INVESTOR_NOT_VERIFIED) {
          window.setTimeout(() =>
            errorToast(
              translate('Errors.investor-not-verified', {
                data: translate('Texts.profile.general-information'),
              }),
            ),
          );
        }
        return {
          ...state,
          isLoading: false,
          isError: true,
        };
      },
    )
    .addCase<string, PayloadAction<void>>(updateGeneralInfoNext.SUCCESS, state => {
      window.setTimeout(() =>
        successToast(
          translate('Toasts.profile.update-request.success', {
            data: translate('Texts.profile.general-information'),
          }),
        ),
      );
      return {
        ...state,
        isLoading: false,
        isProfileModalOpen: false,
      };
    })
    .addCase<string, PayloadAction<boolean>>(SET_PROFILE_MODAL_OPEN, (state, { payload }) => ({
      ...state,
      isProfileModalOpen: payload,
    }))
    .addCase<string, PayloadAction<void>>(updateBankAccountNext.INIT, state => ({
      ...state,
      isLoading: true,
      isError: false,
    }))
    .addCase<string, PayloadAction<IHttpErrorResponse<HttpCommonErrorTypes>>>(
      updateBankAccountNext.ERROR,
      (state, { payload }) => {
        if (payload.name === HttpCommonErrorTypes.INVESTOR_UPDATE_REQUEST_ALREADY_EXISTS) {
          window.setTimeout(() =>
            errorToast(
              translate('Errors.already-requested', {
                data: translate('Texts.profile.update', {
                  item: translate('Texts.profile.bank-account'),
                }),
              }),
            ),
          );
        }
        if (payload.name === HttpCommonErrorTypes.INVESTOR_NOT_VERIFIED) {
          window.setTimeout(() =>
            errorToast(
              translate('Errors.investor-not-verified', {
                data: translate('Texts.profile.bank-account'),
              }),
            ),
          );
        }
        return {
          ...state,
          isLoading: false,
          isError: true,
        };
      },
    )
    .addCase<string, PayloadAction<void>>(updateBankAccountNext.SUCCESS, state => {
      window.setTimeout(() =>
        successToast(
          translate('Toasts.profile.update-request.success', {
            data: translate('Texts.profile.bank-account'),
          }),
        ),
      );
      return {
        ...state,
        isLoading: false,
        isBankModalOpen: false,
      };
    })
    .addCase<string, PayloadAction<boolean>>(SET_BANK_MODAL_OPEN, (state, { payload }) => ({
      ...state,
      isBankModalOpen: payload,
    }))
    .addCase<string, PayloadAction<void>>(updateCompatibilityNext.INIT, state => ({
      ...state,
      isLoading: true,
      isError: false,
    }))
    .addCase<string, PayloadAction<IHttpErrorResponse<HttpCommonErrorTypes>>>(
      updateCompatibilityNext.ERROR,
      (state, { payload }) => {
        if (payload.name === HttpCommonErrorTypes.INVESTOR_UPDATE_REQUEST_ALREADY_EXISTS) {
          window.setTimeout(() =>
            errorToast(
              translate('Errors.already-requested', {
                data: translate('Texts.profile.update', {
                  item: translate('Texts.profile.compatibility'),
                }),
              }),
            ),
          );
        }
        if (payload.name === HttpCommonErrorTypes.INVESTOR_NOT_VERIFIED) {
          window.setTimeout(() =>
            errorToast(
              translate('Errors.investor-not-verified', {
                data: translate('Texts.profile.compatibility'),
              }),
            ),
          );
        }
        return {
          ...state,
          isLoading: false,
          isError: true,
        };
      },
    )
    .addCase<string, PayloadAction<void>>(updateCompatibilityNext.SUCCESS, state => {
      window.setTimeout(() =>
        successToast(
          translate('Toasts.profile.update-request.success', {
            data: translate('Texts.profile.compatibility'),
          }),
        ),
      );
      return {
        ...state,
        isLoading: false,
        isCompatibilityModalOpen: false,
      };
    })
    .addCase<string, PayloadAction<boolean>>(
      SET_COMPATIBILITY_MODAL_OPEN,
      (state, { payload }) => ({
        ...state,
        isCompatibilityModalOpen: payload,
      }),
    )
    .addCase<string, PayloadAction<boolean>>(SET_OTHER_ITEMS_MODAL_OPEN, (state, { payload }) => ({
      ...state,
      isOtherItemsModalOpen: payload,
    }))
    .addCase<string, PayloadAction<void>>(updateInvestorNext.INIT, state => ({
      ...state,
      isLoading: true,
      isError: false,
    }))
    .addCase<string, PayloadAction<void>>(updateInvestorNext.SUCCESS, state => {
      return {
        ...state,
        isLoading: false,
        isProfileModalOpen: false,
        isBankModalOpen: false,
        isCompatibilityModalOpen: false,
      };
    })
    .addCase<string, PayloadAction<void>>(updateInvestorOtherItemsNext.INIT, state => ({
      ...state,
      isLoading: true,
      isError: false,
    }))
    .addCase<string, PayloadAction<void>>(updateInvestorOtherItemsNext.SUCCESS, state => {
      return {
        ...state,
        isLoading: false,
        isOtherItemsModalOpen: false,
      };
    })
    .addCase<string, PayloadAction<void>>(updatePhoneNumberNext.INIT, state => ({
      ...state,
      isLoading: true,
      isError: false,
    }))
    .addCase<string, PayloadAction<IHttpErrorResponse<HttpCommonErrorTypes>>>(
      updatePhoneNumberNext.ERROR,
      (state, { payload }) => {
        if (payload.name === HttpCommonErrorTypes.INVESTOR_UPDATE_REQUEST_ALREADY_EXISTS) {
          window.setTimeout(() =>
            errorToast(
              translate('Errors.already-requested', {
                data: translate('Texts.profile.update', {
                  item: translate('Texts.profile.general-information'),
                }),
              }),
            ),
          );
        }
        if (payload.name === HttpCommonErrorTypes.INVESTOR_NOT_VERIFIED) {
          window.setTimeout(() =>
            errorToast(
              translate('Errors.investor-not-verified', {
                data: translate('Texts.profile.general-information'),
              }),
            ),
          );
        }
        return {
          ...state,
          isLoading: false,
          isError: true,
        };
      },
    )
    .addCase<string, PayloadAction<void>>(updatePhoneNumberNext.SUCCESS, state => {
      window.setTimeout(() =>
        successToast(
          translate('Toasts.profile.update.success', {
            data: translate('Texts.phone-number'),
          }),
        ),
      );
      return {
        ...state,
        isLoading: false,
        isProfileModalOpen: false,
      };
    }),
);
