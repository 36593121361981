import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSettingSelector } from '@state/setting/hooks';
import { createIntl, createIntlCache } from '@formatjs/intl';
import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-displaynames/locale-data/en';
import '@formatjs/intl-displaynames/locale-data/ja';

import { Currency } from '@web-api-client';

type CurrencyOptions = {
  currency?: Currency;
};

export const useCurrency = (options?: CurrencyOptions) => {
  const { isLoading, setting } = useSettingSelector();
  const { i18n } = useTranslation();

  const cache = createIntlCache();
  const intl = createIntl(
    {
      locale: i18n.language?.toLowerCase(),
      defaultLocale: 'en',
    },
    cache,
  );

  const formatCurrency = useCallback(
    (amount: number) => {
      return isLoading
        ? ''
        : intl.formatNumber(amount, {
            style: 'currency',
            currency: options?.currency || setting?.variables.mainCurrency,
            notation: 'standard',
            currencyDisplay: 'symbol',
          });
    },
    [options?.currency, setting?.variables.mainCurrency, isLoading, intl],
  );

  return { formatCurrency };
};
