import React from 'react';
import { useTranslation } from 'react-i18next';
import { CDropdownToggle, CDropdownMenu, CDropdownItem, CDropdown } from '@coreui/react';
import { setActiveLanguage } from '@utils/translate';
import './LanguageSelect.scss';

enum Language {
  EN = 'en',
  JA = 'ja',
}

type LanguageConfiguration = {
  language: Language;
  label: string;
};

const LANGUAGE_CONFIG: { [key: string]: LanguageConfiguration } = {
  en: {
    language: Language.EN,
    label: 'English',
  },
  ja: {
    language: Language.JA,
    label: '日本語',
  },
};

export const getAvailableLanguageConfig = (
  languages: readonly string[],
): LanguageConfiguration[] => {
  return Object.entries(LANGUAGE_CONFIG).reduce((config, [key, value]) => {
    if (languages.some(lang => lang.toLowerCase() === key.toLowerCase())) {
      config.push(value);
    }
    return config;
  }, [] as LanguageConfiguration[]);
};

const LanguageSelect = () => {
  const { i18n } = useTranslation();

  const availableLanguagesConfig = getAvailableLanguageConfig(i18n.options.supportedLngs || ['ja']);

  if (availableLanguagesConfig.length <= 1) {
    return null;
  }

  return (
    <CDropdown className='LanguageSelectComponent'>
      <CDropdownToggle caret id='language-toggle'>
        {i18n.resolvedLanguage?.toUpperCase()}
        <i className='icon-point-down font-20 ml-1' />
      </CDropdownToggle>
      <CDropdownMenu>
        {availableLanguagesConfig.map(({ language, label }) => (
          <CDropdownItem
            id={`language-item-${language}`}
            onClick={() => setActiveLanguage(language)}
            key={language}
          >
            {label}
          </CDropdownItem>
        ))}
      </CDropdownMenu>
    </CDropdown>
  );
};

export default LanguageSelect;
