import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  AdditionalStatusesKycStatusEnum,
  AdditionalStatusesRegistrationStatusEnum,
  RegisteredMailStatusStatusEnum,
} from '@web-api-client';

export function useInvestorSelector(): RootState['investor'] {
  return useSelector<RootState, RootState['investor']>(state => state.investor);
}

export const selectTokenIdVerified = createSelector(
  (state: RootState, tokenId: string) => {
    const tokens = state.investor.investorVerifiedTokens;
    return tokens.includes(tokenId);
  },
  isVerified => isVerified,
);

export function useInvestorTokenVerified(tokenId: string): boolean {
  return useSelector<RootState, boolean>(state => selectTokenIdVerified(state, tokenId));
}

export function useInvestorKYC() {
  const { investor, isRevertedRegistration } = useInvestorSelector();

  const isKycExecuted =
    !!investor?.additionalStatuses &&
    (investor?.additionalStatuses?.kycStatus === AdditionalStatusesKycStatusEnum.Verified ||
      (investor?.additionalStatuses?.registeredMailStatus?.status &&
        investor?.additionalStatuses?.registeredMailStatus?.status !==
          RegisteredMailStatusStatusEnum.None));

  const isKycNotExecutable = isKycExecuted && !isRevertedRegistration;

  const isReadyForKyc = investor?.registrationStatuses?.isReadyForKyc;

  return {
    isKycExecuted,
    isKycNotExecutable,
    isReadyForKyc,
  };
}

export function useInvestorRegistered() {
  const { investor } = useInvestorSelector();
  return {
    isRegistered:
      investor?.additionalStatuses?.registrationStatus ===
      AdditionalStatusesRegistrationStatusEnum.Verified,
  };
}
