import classNames from 'classnames';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import { CLabel, CInput, CFormGroup, CInputGroup, CButton } from '@coreui/react';
import { ErrorMessage } from '@hookform/error-message';
import { fullToHalf } from '@utils/form';
import AutoComplete from '../../AutoComplete/AutoComplete';
import { Translate } from '../../Translate';
import './Combobox.scss';

export type ComboboxOption = {
  label: string;
  ruby: string[];
  value: string;
};

export interface IComboboxProps extends Omit<PropsWithChildren<CInput>, 'label'> {
  label?: string | React.ReactElement;
  errors?: DeepMap<FieldValues, FieldError> | null;
  options: ComboboxOption[];
  listLimit?: number;
  disabled?: boolean;
  formStyle?: boolean;
  notFoundLabel?: string;
  onClear?: () => void;
}

// NOTE: This is not proper combobox, this is only for bank account
// TODO: Refactor this component to be more generic
const Combobox = ({
  className,
  label,
  name = '',
  errors,
  onChange,
  onClear,
  onBlur,
  options = [],
  listLimit = 10,
  disabled = false,
  formStyle = false,
  notFoundLabel = '',
  value,
  ...props
}: IComboboxProps) => {
  const [isShowList, setIsShowList] = useState(false);
  const [textValue, setTextValue] = useState('');
  const [formValue, setFormValue] = useState('');
  const [matchList, setMatchList] = useState<ComboboxOption[]>([]);

  const setValue = (val: string) => {
    const oldValue = formValue;
    const selectedItem = options.find(item => item.value === val);
    const newValue = selectedItem?.value || '';
    setFormValue(newValue);
    setTextValue(selectedItem?.label || notFoundLabel || '');
    if (!newValue && newValue !== oldValue) {
      onClear?.();
    }
  };

  useEffect(() => {
    setValue(String(value));
  }, [value, options]);

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target?.value;
    setTextValue(input);
    if (!input) {
      setIsShowList(false);
      setMatchList([]);
      return;
    }

    const regexp = new RegExp(`^.*${fullToHalf(input).toLowerCase()}.*$`);
    const matchList = options
      .filter(
        item =>
          fullToHalf(item.label)?.toLowerCase()?.match(regexp) ||
          item.ruby?.some(v => v.match(regexp)),
      )
      .sort((a, b) => a.label?.length - b.label?.length)
      .slice(0, listLimit);
    setMatchList(matchList);
    if (matchList.length > 0) {
      setIsShowList(true);
    }
  };

  const clickItemHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target?.value);
    setIsShowList(false);
    onChange?.(e);
  };

  const blurHandler = async (e: React.FocusEvent<HTMLInputElement>) => {
    const selectedItem = options.find(item => item.label === textValue);
    if (!formValue && selectedItem) {
      setValue(selectedItem.value);
      (e.target as HTMLInputElement).value = selectedItem.value;
      onChange?.(e);
    }
    onBlur?.(e);
  };

  const clearHandler = () => {
    setFormValue('');
    setTextValue('');
    onClear?.();
  };

  return (
    <AutoComplete
      isOpen={isShowList}
      onOpen={() => setIsShowList(true)}
      onClose={() => setIsShowList(false)}
      getOptionLabel={item => item.label}
      getOptionValue={item => item.value}
      onSelect={clickItemHandler}
      options={matchList}
      onBlur={blurHandler}
      renderInput={({ inputProps: { onBlur: inputOnBlur } }) => (
        <CFormGroup className={classNames('ComboboxComponent', className)}>
          {label &&
            (typeof label === 'string' ? (
              <CLabel htmlFor={props.htmlFor}>
                <Translate translationKey={label} />
              </CLabel>
            ) : (
              label
            ))}

          <CInput name={name} value={formValue} hidden readOnly />
          <CInputGroup>
            {formValue && !formStyle ? (
              <div className='d-flex w-100 align-items-center' style={{ minHeight: '52px' }}>
                <div className='selected mr-2'>{textValue}</div>
                {!disabled && (
                  <CButton variant='ghost' onClick={clearHandler}>
                    <i className='icon-exit font-20' />
                  </CButton>
                )}
              </div>
            ) : (
              <CInput
                value={textValue}
                onChange={inputHandler}
                onBlur={inputOnBlur}
                disabled={disabled}
                data-toggle='dropdown'
                className='dropdown-toggle'
              />
            )}
          </CInputGroup>

          {errors && (
            <ErrorMessage className='error text-danger' errors={errors} name={name} as='small' />
          )}
        </CFormGroup>
      )}
    />
  );
};

export default Combobox;
