import { RootState } from '@state';
import { LoginProviderConfigTypeEnum, VariableDtoDashboardModeEnum } from '@web-api-client';

export const translations: Record<string, string> = {
  'Buttons.back': '戻る',
  'Buttons.change-password': 'パスワードを変更する',
  'Buttons.click-here': 'ここをクリック',
  'Buttons.create-account': 'ユーザー登録',
  'Buttons.description': '詳しくはこちら',
  'Buttons.detail': '詳細情報',
  'Buttons.edit': '編集',
  'Buttons.login': 'ログイン',
  'Buttons.next': '次へ',
  'Buttons.profile.update': '更新を申請する',
  'Buttons.reload': '再読み込み',
  'Buttons.update': '更新する',
  'Buttons.cancel': 'キャンセル',
  'Buttons.save': '保存',
  'Buttons.register': '登録',
  'Buttons.delete': '削除',
  'Errors.2fa-code-invalid': '6桁のコードを入力してください',
  'Errors.account-locked': 'アカウントがロックされました。10分後に再度お試しください。',
  'Errors.age-restriction':
    '申し訳ありませんが、{under}歳以下、{over}歳以上の方のアカウント作成はお断りさせていただいております。',
  'Errors.already-requested': '{data}はすでに依頼されています',
  'Errors.cannot-input-same-password': '現在と異なるパスワードを入力してください',
  'Errors.checkbox-unchecked': 'チェックが必要な項目です',
  'Errors.email-invalid': '有効なメールアドレス形式を使用してください。例：suzuki@gmail.com',
  'Errors.file-size': 'ファイルサイズは{maxBytes}までです',
  'Errors.invalid-bank-account-name':
    '全角カナ、全角英数字、全角スペース、全角記号（ ） ． ー ／  「 」のみ入力してください',
  'Errors.invalid-value': '有効な値を入力してください',
  'Errors.invest-age-restriction':
    '大変申し訳ございませんが、ご投資いただける年齢に制限をかけさせていただいております。',
  'Errors.invest-country-code-restriction':
    '{data}非居住者の方はこの商品にはお申し込みいただけません。',
  'Errors.invest-country-code-restriction-JP': '日本',
  'Errors.invest-risk-level-restriction': '投資可能な基準を満たしておりません。',
  'Errors.investment-already-exists': '既にお申込み済みです',
  'Errors.cross-opportunity-investment-limit-exceeded':
    '複数案件の投資上限額を超えています（投資可能額：{investable_amount}、投資上限額：{invest_limit}）',
  'Errors.investor-not-verified': '{data}の確認が完了していないため、更新依頼ができません',
  'Errors.kana-invalid': 'カナのみ入力してください',
  'Errors.kyc-unavailable':
    '本人確認を実施することができません。入力内容をご確認のうえ、再度実行してください。',
  'Errors.length-max': '入力可能文字数を超えています(最大{max}字まで)',
  'Errors.length-min': '少なくとも{min}文字必要です',
  'Errors.login.wrong-email-or-password': '電子メールアドレスまたはパスワードが違います\n',
  'Errors.max': '最大値は{max}です',
  'Errors.min': '最小値は{min}です',
  'Errors.need-to-reinput':
    '要修正の項目があります。本人情報・金融機関口座情報・投資経験などがすべて再入力されていることをご確認ください。',
  'Errors.not-match-profile': '本人確認情報と一致していません',
  'Errors.not-usable-characters': '使用できない文字が含まれています',
  'Errors.hiragana-not-allowed': '使用できないひらがなが含まれています',
  'Errors.katakana-not-allowed': '使用できないカタカナが含まれています',
  'Errors.kanji-not-allowed': '使用できない漢字が含まれています',
  'Errors.half-width-katakana-not-allowed': '使用できない半角カタカナが含まれています',
  'Errors.full-width-ascii-not-allowed': '使用できない全角英数字が含まれています',
  'Errors.whitespace-not-allowed': '使用できない空白が含まれています',
  'Errors.non-sjis-character-not-allowed': '使用できない文字が含まれています: {char}',
  'Errors.symbol-not-allowed': '使用できないシンボルが含まれています: {char}',
  'Errors.character-not-allowed': '使用できない文字が含まれています: {char}',
  'Errors.number-invalid': '数字のみ入力してください',
  'Errors.invalid-phone-number': '不正な電話番号です。正しい電話番号を入力してください',
  'Errors.optional': '任意',
  'Errors.password-invalid': 'パスワードには、数字、大文字、小文字を含める必要があります',
  'Errors.password-max': 'パスワードは最大{max}文字です',
  'Errors.password-min': 'パスワードは少なくとも{min}文字必要です',
  'Errors.recaptcha-invalid': '無効なreCaptchaです',
  'Errors.registration.email-already-in-use':
    'この電子メールアドレスは既に利用されています。 別のアドレスをお試しください。',
  'Errors.required-invalid': '入力が必須の項目です',
  'Errors.resigned': '退会済みです',
  'Errors.security.invalid-password': '無効なパスワードです',
  'Errors.security.password-must-be-new': '現在設定されているものと同じパスワードです',
  'Errors.server-error': 'エラーが発生しています',
  'Errors.space-invalid-name': '姓と名の間に全角スペースを入力してください',
  'Errors.sso.logged-out.description':
    'このウィンドウを閉じて、再度STO取引画面で操作をしてください。',
  'Errors.sso.logged-out.title': 'STO取引画面との接続を切りました。',
  'Errors.sso.unauthorized.description': 'このウィンドウを閉じて、再度操作をやり直してください。',
  'Errors.sso.unauthorized.title': '不正な画面遷移が行われました。',
  'Errors.unauthorized': '有効なセッションがありません。ログインし、認証を行ってください。',
  'Errors.update-request-rejected':
    '{type}の更新依頼が却下されました。情報をお確かめのうえ、再度申請してください。\\n\n<理由> {reason}',
  'Errors.wrong-2fa-code': '二要素認証コードが違います',
  'Errors.wrong-password': 'パスワードが違います',
  'Errors.document-fetch-failed': 'ドキュメントの取得に失敗しました',
  'Menu.documents': 'ドキュメント',
  'Menu.faq': 'FAQ',
  'Menu.opportunities': 'ファンド一覧',
  'Menu.opportunities.cb-self-offering': '社債一覧',
  'Menu.portfolio': 'ポートフォリオ',
  'Menu.tokens': 'トークン売買',
  'Texts.2fa.authy-app': 'Authy（AppStoreまたはGoogle Playでインストールが可能です）',
  'Texts.2fa.backup-key': 'バックアップキー',
  'Texts.2fa.disable-2fa': '二要素認証を解除する',
  'Texts.2fa.do-later': '後で実施',
  'Texts.2fa.download-app': '二要素認証アプリをダウンロードしてください',
  'Texts.2fa.enter-password': '二要素認証を設定するためにパスワードを入力してください',
  'Texts.2fa.google-authenticator':
    'Google Authenticator（AppStoreまたはGoogle Playでインストールが可能です）',
  'Texts.2fa.input-six-digits-code': '6桁コードを入力',
  'Texts.2fa.key-name': '{issuerName} ダッシュボード',
  'Texts.2fa.recommend-description':
    'あなたの詳細情報を安全に保護するために、2要素認証を有効にすることをお勧めします。',
  'Texts.2fa.scan-code': 'ご利用の二要素認証アプリで下記QRコードをスキャンしてください',
  'Texts.2fa.set-2fa': '二要素認証を設定する',
  'Texts.2fa.title': '二要素認証',
  'Texts.2fa.verify-code-description':
    '二要素認証アプリを開き、画面に表示されている6桁のコードを入力してください',
  'Texts.address': '住所',
  'Texts.address.additional-address-info': '建物名等',
  'Texts.address.additional-address-info-placeholder': '○○マンション123号室',
  'Texts.address.city-town': '市区町村',
  'Texts.address.city-town-placeholder': '練馬区',
  'Texts.address.city-town-street-address': '市区町村・番地',
  'Texts.address.city-town-street-address-placeholder': '練馬区1-11-11',
  'Texts.address.prefecture': '都道府県',
  'Texts.address.prefecture-placeholder': '東京都',
  'Texts.address.street-address': '町名以降',
  'Texts.address.street-address-placeholder': '練馬1-11-11',
  'Texts.bank.account-name-kana': '口座名義（カナ）',
  'Texts.bank.account-number': '口座番号',
  'Texts.bank.account-type': '口座種別',
  'Texts.bank.bank-name': '銀行名',
  'Texts.bank.branch-name': '支店名',
  'Texts.bank.current-account': '当座',
  'Texts.bank.savings-account': '普通',
  'Texts.bank.upload-documents': '書類アップロード',
  'Texts.compatibility.amount-of-assets': '資産の状況',
  'Texts.compatibility.amount-of-assets.10-30-million-yen': '1,000～3,000万円',
  'Texts.compatibility.amount-of-assets.100-million-yen-more': '1億円以上',
  'Texts.compatibility.amount-of-assets.3-10-million-yen': '300～1,000万円',
  'Texts.compatibility.amount-of-assets.30-50-million-yen': '3,000万円～5,000万円',
  'Texts.compatibility.amount-of-assets.50-100-million-yen': '5,000～万円～1億円',
  'Texts.compatibility.amount-of-assets.less-than-3million-yen': '300万円未満',
  'Texts.compatibility.annual-income': '年収',
  'Texts.compatibility.annual-income.10-20million-yen': '1,000～2,000万円',
  'Texts.compatibility.annual-income.20-30million-yen': '2,000～3,000万円',
  'Texts.compatibility.annual-income.3-5million-yen': '300～500万円',
  'Texts.compatibility.annual-income.30million-yen-more': '3,000万円以上',
  'Texts.compatibility.annual-income.5-10million-yen': '500～1,000万円',
  'Texts.compatibility.annual-income.less-than-3million-yen': '300万円未満',
  'Texts.compatibility.applicable': '該当します',
  'Texts.compatibility.asset-amount': '資産の状況',
  'Texts.compatibility.asset-amount.10-30-million-yen': '1,000～3,000万円',
  'Texts.compatibility.asset-amount.100-million-yen-more': '1億円以上',
  'Texts.compatibility.asset-amount.3-10-million-yen': '300～1,000万円',
  'Texts.compatibility.asset-amount.30-50-million-yen': '3,000万円～5,000万円',
  'Texts.compatibility.asset-amount.50-100-million-yen': '5,000～万円～1億円',
  'Texts.compatibility.asset-amount.less-than-3million-yen': '300万円未満',
  'Texts.compatibility.asset-type': '資金の種類',
  'Texts.compatibility.asset-type.description':
    '<div style="text-align: left;"><b>余裕資金</b><br /> 当面使うことがない資金です。一時的にリターンがマイナスとなっても、長期の投資で挽回できる可能性があり、積極運用に向くと言われます。<br /><br /> <b>準備資金</b><br /> 数年後に使うことが決まっているお金のことです。元本の安全性が確保されていて、一定の収益が期待できる商品の活用が考えられます。<br /><br /> <b>生活資金</b><br /> 日々の生活など、すぐに使うお金です。あまり運用に向いていないため、換金性があって、安全性の高い資産にしか投資できません。<br /><br /></div>',
  'Texts.compatibility.asset-type.living-expenses': '生活資金',
  'Texts.compatibility.asset-type.reserve-fund': '準備資金',
  'Texts.compatibility.asset-type.surplus-funds': '余裕資金',
  'Texts.compatibility.financial-business-experience': '金融に係る業務経験',
  'Texts.compatibility.financial-business-experience.no': '無し',
  'Texts.compatibility.financial-business-experience.yes': '有り',
  'Texts.compatibility.foreign-currency-deposits': '外貨預金',
  'Texts.compatibility.foreign-politically-exposed-person': '外国PEPsへの該当性',
  'Texts.compatibility.foreign-politically-exposed-person-description':
    '<div style="text-align: left;">外国PEPsとは、外国の政府等の要人（外国の政府等において重要な地位を占める方）とその地位にあった方およびそのご家族を指します。下記のいずれかに該当されないかどうかご確認ください。\n' +
    '<ol>\n' +
    '<li>外国の元首</li>\n' +
    '<li>外国において、日本における内閣総理大臣その他国務大臣および副大臣に相当する方</li>\n' +
    '<li>外国において、日本における衆議院議長、衆議院副議長、参議院議長または参議院副議長に相当する方</li>\n' +
    '<li>外国において、日本における最高裁判所の裁判官に相当する方</li>\n' +
    '<li>外国において、日本における特命全権大使、特派大使、政府代表または全権委員に相当する方</li>\n' +
    '<li>外国において、日本における統合幕僚長、統合幕僚副長、陸上幕僚長、陸上幕僚副長、海上幕僚長、海上幕僚副長、航空幕僚長または航空幕僚副長に相当する方</li>\n' +
    '<li>中央銀行の役員の方</li>\n' +
    '<li>予算について、国会の議決を経、または承認を受けなければならない法人の役員（日本における沖縄復興開発金融公庫等の政府系金融機関等のような、外国において公共性と信用力を有する法人を指す）</li>\n' +
    '<li>過去に、上記1～8のいずれかに該当していた方</li>\n' +
    '<li>上記1～9のいずれかに該当する方のご家族（配偶者（婚姻の届出をしていないが、事実上婚姻関係にある方を含む）、父母、子および兄弟姉妹、配偶者の父母および子）</li>\n' +
    '</ol></div>',
  'Texts.compatibility.forex-margin-trading': '外国為替証拠金取引',
  'Texts.compatibility.futures': '商品先物',
  'Texts.compatibility.investment-experience': '投資経験',
  'Texts.compatibility.investment-experience.less-than-1-year': '1年未満',
  'Texts.compatibility.investment-experience.less-than-3-years': '3年未満',
  'Texts.compatibility.investment-experience.less-than-5-years': '5年未満',
  'Texts.compatibility.investment-experience.more-than-5-years': '5年以上',
  'Texts.compatibility.investment-experience.none': 'なし',
  'Texts.compatibility.investment-policy': '投資で重視すること',
  'Texts.compatibility.investment-policy.description':
    '<div style="text-align: left;"><b>元本の安全性重視</b><br />収益は低くても、元本割れとなるリスクが極力小さいことを重視する<br /><br /><b>分配金による安定的な収入重視</b><br />分配金を重視した中長期的かつ安定的な運用を望むが、ある程度の元本割れとなるリスクも許容する<br /><br /><b>分配金や利金による収入と共に、値上がり益を追求</b><br />分配金や利金を重視するだけでなく、値上がり益も追求するため、相応の元本割れリスクも許容する<br /><br /><b>利回り・値上がり益を追求</b><br />積極的に利回り・値上がり益を追求するため、元本割れとなるリスクがある程度大きくても許容する<br /><br /><b>高い利回り・大幅な値上がり益を追求</b><br />積極的に高い利回り・値上がり益を追求するため、元本割れとなるリスクが非常に大きくても許容する<br /><br /></div>',
  'Texts.compatibility.investment-policy.high-price-increases':
    '高い利回り・大幅な値上がり益を追求',
  'Texts.compatibility.investment-policy.price-increases': '利回り・値上がり益を追求',
  'Texts.compatibility.investment-policy.price-increases-with-distribution':
    '分配金や利金による収入と共に、値上がり益を追求',
  'Texts.compatibility.investment-policy.safety-of-principal': '元本の安全性重視',
  'Texts.compatibility.investment-policy.stable-income': '分配金による安定的な収入重視',
  'Texts.compatibility.investment-purpose': '投資目的',
  'Texts.compatibility.investment-purpose.long-term-asset-management': '長期的な資産運用として',
  'Texts.compatibility.investment-purpose.otherwise': 'その他',
  'Texts.compatibility.investment-purpose.short-term-price-movements':
    '短期的な値動きによる利益獲得',
  'Texts.compatibility.investment-trust': '投資信託',
  'Texts.compatibility.job': '職業',
  'Texts.compatibility.job.college-students': '大学生・大学院生',
  'Texts.compatibility.job.company-employee-contract-employees': '会社員（契約社員）',
  'Texts.compatibility.job.company-employee-full-time': '会社員（正社員）',
  'Texts.compatibility.job.company-employee-temporary-employees': '会社員（派遣社員）',
  'Texts.compatibility.job.doctor': '医師',
  'Texts.compatibility.job.free-lance': '自由業',
  'Texts.compatibility.job.high-school-students': '高校生',
  'Texts.compatibility.job.housewife': '専業主婦・主夫',
  'Texts.compatibility.job.managers-and-officials': '経営者・役員',
  'Texts.compatibility.job.npo-corporation-staff': 'NGO・NPO法人職員',
  'Texts.compatibility.job.part-time-job': 'パート・アルバイト',
  'Texts.compatibility.job.professional-school-students': '専門学校生・短大生',
  'Texts.compatibility.job.public-officials': '公務員',
  'Texts.compatibility.job.retirement': '定年退職',
  'Texts.compatibility.job.self-employed': '自営業',
  'Texts.compatibility.job.shigyo': '士業（公認会計士・弁護士・税理士・司法書士）',
  'Texts.compatibility.job.stay-at-home': '家事手伝い',
  'Texts.compatibility.job.unemployed': '無職',
  'Texts.compatibility.kind-of-funds': '資金の種類',
  'Texts.compatibility.kind-of-funds.description':
    '<div style="text-align: left;"><b>余裕資金</b><br /> 当面使うことがない資金です。一時的にリターンがマイナスとなっても、長期の投資で挽回できる可能性があり、積極運用に向くと言われます。<br /><br /> <b>準備資金</b><br /> 数年後に使うことが決まっているお金のことです。元本の安全性が確保されていて、一定の収益が期待できる商品の活用が考えられます。<br /><br /> <b>生活資金</b><br /> 日々の生活など、すぐに使うお金です。あまり運用に向いていないため、換金性があって、安全性の高い資産にしか投資できません。<br /><br /></div>',
  'Texts.compatibility.kind-of-funds.living-expenses': '生活資金',
  'Texts.compatibility.kind-of-funds.reserve-fund': '準備資金',
  'Texts.compatibility.kind-of-funds.surplus-funds': '余裕資金',
  'Texts.compatibility.main-source-of-income': '主な収入源',
  'Texts.compatibility.main-source-of-income.business-income': '事業収入',
  'Texts.compatibility.main-source-of-income.employment-income': '給与収入',
  'Texts.compatibility.main-source-of-income.head-of-household-income': '世帯主の収入',
  'Texts.compatibility.main-source-of-income.interest-and-dividend-income': '利子・配当収入',
  'Texts.compatibility.main-source-of-income.none': '無し',
  'Texts.compatibility.main-source-of-income.otherwise': 'その他',
  'Texts.compatibility.main-source-of-income.pension': '年金',
  'Texts.compatibility.main-source-of-income.real-estate-income': '不動産収入',
  'Texts.compatibility.margin-trading': '信用取引・その他の金融先物',
  'Texts.compatibility.not-applicable': '該当しません',
  'Texts.compatibility.public-bond': '公社債',
  'Texts.compatibility.stock': '株式',
  'Texts.date-of-birth': '生年月日',
  'Texts.date-of-birth.day': '日',
  'Texts.date-of-birth.month': '月',
  'Texts.date-of-birth.year': '年',
  'Texts.delete': '削除',
  'Texts.documents.agreement-of-digital-securities': 'デジタル証券総合取引約款',
  'Texts.documents.agreement-of-joint-money-trust': '実績配当型合同運用指定金銭信託約款',
  'Texts.documents.agreement-of-joint-money-trust-usd':
    '米ドル建て実績配当型合同運用指定金銭信託約款',
  'Texts.documents.amendments-for-prospectus': '目論見書訂正事項分',
  'Texts.documents.before-agreement-of-protected-deposit-of-digital-securities':
    'デジタル証券の保護預かりに関する契約締結前交付書面',
  'Texts.documents.before-contract-conclusion-document': '契約成立前書面',
  'Texts.documents.confirmation-about-sending-email-and-send-date-of-contract-conclusion-document':
    '契約成立時書面のメール送信ならびに送信日についてのご確認',
  'Texts.documents.confirmed-date': '確認日/メール送信日',
  'Texts.documents.date-of-issue': '発行日',
  'Texts.documents.contract-conclusion-document': '契約成立時書面',
  'Texts.documents.document-title': '名称',
  'Texts.documents.document-to-notify-important-matters-for-companies-act':
    '会社法上の募集事項通知',
  'Texts.documents.download': 'ダウンロード',
  'Texts.documents.entry-important-matters': '重要説明事項（本申込み時）',
  'Texts.documents.explanation-of-electronic-delivery': '電子的公布の説明',
  'Texts.documents.general-conditions-of-specified-joint-real-estate-venture-contract':
    '不動産特定共同事業契約約款',
  'Texts.documents.important-matters-of-digital-securities': 'デジタル証券の重要事項説明書',
  'Texts.documents.important-matters-of-electronic-trading-operations':
    '電子取引業務に関わる重要事項',
  'Texts.documents.opportunity.confirmed-documents': '確認書類',
  'Texts.documents.opportunity.fund': 'ファンド',
  'Texts.documents.opportunity.fund.cb-self-offering': '社債',
  'Texts.documents.payout-notification': '支払通知書-{date}',
  'Texts.documents.pre-entry-important-matters': '重要説明事項（抽選申込み時）',
  'Texts.documents.product-description': '商品内容説明書',
  'Texts.documents.prospectus': '発行登録追補目論見書',
  'Texts.documents.tabs.annual-transaction-report': '年間取引報告書',
  'Texts.documents.tabs.domain': '同意書',
  'Texts.documents.tabs.opportunity': 'ファンド関連書類',
  'Texts.documents.tabs.opportunity.cb-self-offering': '社債関連書類',
  'Texts.documents.terms-of-use': '利用規約',
  'Texts.documents.title': 'ドキュメント',
  'Texts.documents.written-consent': '承諾書',
  'Texts.documents.annual-transaction-report': '年間取引報告書',
  'Texts.tokens.title': 'トークン売買',
  'Texts.tokens.fetch-failed': '{name}の取得に失敗しました',
  'Texts.tokens.retry-fetch': '再取得する',
  'Texts.tokens.connect-to-wallet': 'ウォレットを接続',
  'Texts.tokens.registration-progress.description':
    'トークンを売買するには、全ての登録を済ませていただく必要があります。',
  'Texts.tokens.wallets.sign-wallet': `ウォレットを追加するには、このメッセージを5分以内で署名してください。

追加されるウォレットのアドレス：{address}

タイムスタンプ：{timestamp}`,
  'Texts.tokens.wallets.add-wallet': 'ウォレットを追加',
  'Texts.tokens.wallets.no-wallets': '表示できるウォレットがありません',
  'Texts.tokens.wallets.no-held-tokens': '現在保有するトークンはありません',
  'Texts.tokens.wallets.edit-wallet-title': 'ウォレットを編集',
  'Texts.tokens.wallets.delete-wallet-title': 'ウォレットを削除',
  'Texts.tokens.wallets.delete-wallet': '{name} の登録を削除します。本当によろしいですか？',
  'Texts.tokens.wallets.wallet-name': 'ウォレット名',
  'Texts.tokens.wallets.title': 'ウォレット',
  'Texts.tokens.wallets.default-wallet-name': 'ウォレット',
  'Texts.tokens.wallets.status.connected': '接続中',
  'Texts.tokens.wallets.status.not-connected': '未接続',
  'Texts.tokens.trade.title': 'トークン売買',
  'Texts.tokens.trade.no-registered-wallets': 'ウォレットが登録されていません',
  'Texts.tokens.trade.sell': '売却',
  'Texts.tokens.trade.opportunities': 'ファンド',
  'Texts.tokens.trade.no-invested-funds': '投資中のファンドがありません',
  'Texts.tokens.trade.buy': '購入',
  'Texts.tokens.trade.tokens': 'ウォレット保有/全トークン',
  'Texts.tokens.trade.no-investable-funds': '購入出来るファンドがありません',
  'Texts.tokens.trade.sign-in-wallet': 'ウォレットで署名をしてください。',
  'Texts.tokens.trade.pending-investor-update':
    '投資家情報の変更中にはお手続きいただくことができません。お手続きの完了をお待ちください。',
  'Texts.tokens.trade.step.processing': '処理完了まで、10分程度かかる場合があります',
  'Texts.tokens.trade.step.investor-information-sell': '投資家情報の確認',
  'Texts.tokens.trade.step.investor-information-buy': '書面等の確認',
  'Texts.tokens.trade.step.investor-information-button': '確認する',
  'Texts.tokens.trade.step.wallet-registration': 'ウォレット登録',
  'Texts.tokens.trade.step.wallet-registration-button': '登録する',
  'Texts.tokens.trade.step.wallet-registration-hint':
    'ファンドごとに、トークン引き出し先のウォレットをご登録いただきます。登録ごとにガス代がかかります。',
  'Texts.tokens.trade.step.token-withdraw': 'トークン引き出し',
  'Texts.tokens.trade.step.token-withdraw-button': '引き出す',
  'Texts.tokens.trade.step.token-withdraw-hint':
    'トークンを登録されたウォレットに引き出します。引き出しにはガス代がかかります。',
  'Texts.tokens.trade.step.order': 'オーダー作成',
  'Texts.tokens.trade.step.order-button': '取引ツールへ',
  'Texts.tokens.trade.step.order-hint':
    '取引ツールで購入/売却オーダーを作成するか、相手からシェアされたリンクより購入/売却します。',
  'Texts.tokens.trade.dialog.investor-information.title': '投資家情報の確認',
  'Texts.tokens.trade.dialog.investor-information.already-completed':
    '投資家情報の確認は既に完了されています。',
  'Texts.tokens.trade.dialog.investor-information.previous-steps-incomplete':
    '接続されたウォレットはまだ追加されていません。',
  'Texts.tokens.trade.dialog.investor-information.checkbox-label':
    '投資家情報に変更はありません。（変更がある場合、プロフィール画面で最新の情報をご入力ください。審査完了後に取引可能となります。）',
  'Texts.tokens.trade.dialog.wallet-registration.title': 'ファンドへのウォレット登録',
  'Texts.tokens.trade.dialog.wallet-registration.already-completed':
    '接続されたウォレットはもう登録済みです。',
  'Texts.tokens.trade.dialog.wallet-registration.previous-steps-incomplete':
    'ウォレット登録の前のステップはまだ未完了です。',
  'Texts.tokens.trade.dialog.wallet-registration.description':
    'ファンドに対し、ウォレットをご登録ください。\nウォレットの登録にはブロックチェーンのガス代金のお支払いが必要です。処理完了には、トランザクション成功後に10分程度かかる場合があります。',
  'Texts.tokens.trade.dialog.wallet-registration.wallet': 'ウォレット',
  'Texts.tokens.trade.dialog.wallet-registration.no-wallet': '選択できるウォレットがありません',
  'Texts.tokens.trade.dialog.wallet-registration.wallet-required':
    '接続されているウォレットが投資家ウォレットに追加されてないです。',
  'Texts.tokens.trade.dialog.wallet-registration.wallet-mismatch':
    '接続されているウォレットがご選択されたウォレットと異なります。',
  'Texts.tokens.trade.dialog.token-withdraw.title': 'トークン引き出し',
  'Texts.tokens.trade.dialog.token-withdraw.description':
    '指定した数量のトークンを登録済みのウォレットに引き出します。',
  'Texts.tokens.trade.dialog.token-withdraw.target-wallet': '引き出し先のウォレット',
  'Texts.tokens.trade.dialog.token-withdraw.error-disabled':
    'このウォレットは投資家のウォレットに追加されていません。',
  'Texts.tokens.trade.dialog.token-withdraw.error-investor-information':
    'このトークンの投資家情報の確認はまだ未完了です。',
  'Texts.tokens.trade.dialog.token-withdraw.error-wallet-registration':
    'このウォレットを投資家ウォレットに追加するか、登録済みのウォレットに切り替えてください。',
  'Texts.tokens.trade.dialog.token-withdraw.error-trade':
    '全トークンはもう既に引き出されています。',
  'Texts.tokens.trade.dialog.token-input.label': '引き出し数量の指定',
  'Texts.tokens.trade.dialog.token-input.info': '引き出し可能な最大数：',
  'Texts.tokens.trade.dialog.token-input.min': '最小数より小さいです',
  'Texts.tokens.trade.dialog.token-input.max': '最大数より大きいです',
  'Texts.tokens.trade.dialog.token-input.decimal': '小数点は使えません',
  'Texts.email': '電子メール',
  'Texts.entry.complete.back-button': 'ご入金先案内画面へ',
  'Texts.entry.complete.description':
    'お申込みありがとうございます。割当数量及び払込金額を記載した電子メールを投資家様のメールアドレスに送信いたしましたので、当該メールを必ずご確認いただけますようお願いいたします。',
  'Texts.entry.complete.subtitle': 'お申込みが完了しました。',
  'Texts.entry.complete.title': '完了',
  'Texts.entry.confirm.description':
    '<div><ul><li>本申込み完了後、割当数量及び払込金額を記載した電子メールを投資家様のメールアドレスに送信いたします。割当数量については、当該メールを投資家様が受領した時点で全額購入義務が発生いたします。</li><li>&nbsp;当社は、投資家様より申込額を申込証拠金としてお支払いいただいた際に、特定商取引法に基づく一定の事項に関する通知を電子交付いたします。本申込みをするボタンを押すことで、当該電子交付に同意いただいたものとみなされます。<br>※電子交付とは、当社のメールアカウントから電子メールで特定商取引法に基づく一定の事項を送信し、当該電子メールを受信した投資家様のパソコン又はスマートフォンでご覧いただくことをいいます。なお、投資家様が当該事項をパソコン又はスマートフォンで保存するにあたっては、Microsoft Outlook、Gmail等のメールソフトが必要となります。ただし、当該電子メールは、株式会社エポスカードのメールアドレスから送付される予定です。本申込みをするボタンを押すことで、当該方法により電子メールを受領することに同意いただいたものとみなされます。</li><li>本申込み完了後の金額変更及びキャンセルは、当社に社債契約上の義務違反があった場合を除き、お受けできません。</li><li>エポスカードのお支払状況等により請求不可だった場合や残高不足等の理由により投資家様からの申込証拠金の口座引き落としが確認できなかった場合、当該投資家様のお申込みはお取消しいたします。</li></ul></div>',
  'Texts.entry.confirm.submit': '本申込する',
  'Texts.entry.confirm.subtitle': 'お申込み内容をご確認ください',
  'Texts.entry.confirm.title': '本申込み',
  'Texts.entry.consent.checked': '上記書類をダウンロードし、内容について同意しました。',
  'Texts.entry.consent.description':
    '投資家様は、会社法第677条第2項に基づいて本社債の引受けの申込みに関して当社に対して交付すべき書面に記載すべき事項を、本プラットフォームを通じたメッセージに記載し送信する方法で当社に提出します。<br>下記の承諾書を投資家様のパソコン又はスマートフォンにダウンロードし、内容をご確認のうえ、以下のチェックボックスにチェックを入れて次のステップにお進みください。',
  'Texts.entry.consent.subtitle': '書類確認',
  'Texts.entry.consent.title': '電磁的申込み承諾書の確認',
  'Texts.entry.prospectus.checked': '上記書類をダウンロードし、内容について同意しました。',
  'Texts.entry.prospectus.description':
    '下記の目論見書および重要説明事項を投資家様のパソコン又はスマートフォンにダウンロードし、内容をご確認のうえ、以下のチェックボックスにチェックを入れて次のステップにお進みください。',
  'Texts.entry.prospectus.subtitle': '書類確認',
  'Texts.entry.prospectus.title': '書類ダウンロード',
  'Texts.faq.sub-title': 'よくある質問',
  'Texts.faq.title': 'FAQ',
  'Texts.file-input.browse': 'ファイル選択',
  'Texts.file-input.uploaded': 'ファイルアップロード済',
  'Texts.gender': '性別',
  'Texts.gender.empty': '回答しない',
  'Texts.gender.female': '女性',
  'Texts.gender.male': '男性',
  'Texts.login.do-not-have-account': 'はじめてのご利用はこちらから',
  'Texts.login.forget-password': 'パスワードをお忘れですか？',
  'Texts.login.password': 'パスワード',
  'Texts.menu.go-home': 'ホームへ戻る',
  'Texts.menu.log-out': 'ログアウト',
  'Texts.menu.profile': 'プロフィール',
  'Texts.menu.security': 'セキュリティ',
  'Texts.menu.status': 'ステータス',
  'Texts.name': '氏名',
  'Texts.name-kana': '氏名（カナ）',
  'Texts.nationalities': '国籍',
  'Texts.opportunities.title': 'ファンド一覧',
  'Texts.opportunities.title.cb-self-offering': '社債一覧',
  'Texts.opportunity.application-period': '募集期間',
  'Texts.opportunity.complete.description':
    '引用に編集制限著作削除さでななっ利用フリー、です以上と有償日本応じ著作な自由引用作品か、上に許諾ない、保護ににを世界中の編集、に関する権またを文書に日本語するによる、、定義米国利用かにおける改変は被が本こと許諾のなお、となど等いい説明に要件ことない利。',
  'Texts.opportunity.complete.title': 'お申込みありがとうございます。',
  'Texts.opportunity.confirm.fund-name': 'ファンド名',
  'Texts.opportunity.confirm.fund-name.cb-self-offering': '社債名',
  'Texts.opportunity.confirm.invest': '上記内容で投資する',
  'Texts.opportunity.confirm.invest-amount': 'お申込み金額',
  'Texts.opportunity.confirm.invest-unit': 'お申込み数量',
  'Texts.opportunity.confirm.title': 'お申込み内容の確認',
  'Texts.opportunity.confirmed': '確認済み',
  'Texts.opportunity.confirming': '本申込に進む',
  'Texts.opportunity.documents': '確認書類',
  'Texts.opportunity.expected-operation-period': '想定運用期間',
  'Texts.opportunity.expected-yield': '想定利回り',
  'Texts.opportunity.input-unit-after-change': '*変更後のお申込み数量をご入力ください',
  'Texts.opportunity.interest-rate': '利率',
  'Texts.opportunity.invest': '投資する',
  'Texts.opportunity.invest.cb-self-offering': '抽選申込に進む',
  'Texts.opportunity.invested': '投資済み',
  'Texts.opportunity.invested-amount': '現在のお申込み額：{amount}',
  'Texts.opportunity.invested-unit': '現在のお申込み数量：{unit} 単位',
  'Texts.opportunity.investing-amount': '投資予定額 {amount}',
  'Texts.opportunity.investing-amount-restriction':
    '*1 単位：{amountPerUnit}、最低投資数量：{min}、最大投資数量：{max}',
  'Texts.opportunity.max-amount-to-be-raised': '募集金額',
  'Texts.opportunity.min-amount-to-be-raised': '最低成立金額',
  'Texts.opportunity.no-upper-limit': '上限なし',
  'Texts.opportunity.operation-period': '運用期間',
  'Texts.opportunity.opportunity-agreement-title': 'ご契約にあたってのご質問・ご同意事項',
  'Texts.opportunity.opportunity-agreement-body':
    '以下の確認事項に同意いただける場合はチェックボックスをクリックしてください。同意いただけない場合はこの取引をお取り扱いできません。',
  'Texts.opportunity.progress.completed': '成立済み（募集受付中）',
  'Texts.opportunity.progress.failed': 'ファンド不成立',
  'Texts.opportunity.progress.failed.cb-self-offering': '社債不成立',
  'Texts.opportunity.progress.opened': '成立まであと {amount}',
  'Texts.opportunity.progress.operating': 'ファンド運用中',
  'Texts.opportunity.progress.operating.cb-self-offering': '社債運用中',
  'Texts.opportunity.progress.operation-ended': 'ファンド運用終了',
  'Texts.opportunity.progress.operation-ended.cb-self-offering': '社債運用終了',
  'Texts.opportunity.progress.pending': '未定',
  'Texts.opportunity.progress.redeemed': 'ファンド償還済み',
  'Texts.opportunity.progress.redeemed.cb-self-offering': '社債償還済み',
  'Texts.opportunity.progress.title': '募集状況',
  'Texts.opportunity.requested': 'お申込み内容確認中',
  'Texts.opportunity.require-kyc': '本人確認して投資する',
  'Texts.opportunity.require-login': 'ログインして投資する',
  'Texts.opportunity.status.completed': '募集終了',
  'Texts.opportunity.status.failed': '不成立',
  'Texts.opportunity.status.not-opened': '募集前',
  'Texts.opportunity.status.opened': '募集中',
  'Texts.opportunity.status.operating': '運用中',
  'Texts.opportunity.status.operation-ended': '運用終了',
  'Texts.opportunity.status.redeemed': '償還済み',
  'Texts.opportunity.term': '年限',
  'Texts.opportunity.unconfirmed': '未確認',
  'Texts.opportunity.unit': '{unit} 単位',
  'Texts.opportunity.update-requested': '本人情報変更中',
  'Texts.other': 'その他',
  'Texts.password': 'パスワード',
  'Texts.phone-number': '電話番号',
  'Texts.portfolio.application-detail': 'お申込み内容',
  'Texts.portfolio.change-pre-entry': '変更',
  'Texts.portfolio.payment-type.currency': '金銭',
  'Texts.portfolio.payment-type.currency.usd': '金銭 (USD)',
  'Texts.portfolio.deposit': '入金',
  'Texts.portfolio.detail': '詳細',
  'Texts.portfolio.detail-to-change-pre-entry': '詳細・数量変更',
  'Texts.portfolio.detail-to-entry': '詳細・本申込み',
  'Texts.portfolio.dividend': '配当',
  'Texts.portfolio.dividend-date': '利払い・償還日',
  'Texts.portfolio.dividend-history': '利払い/償還履歴',
  'Texts.portfolio.dividend-units': '保有数量',
  'Texts.portfolio.entry': '本申込に進む',
  'Texts.portfolio.fund.applied-amount-pledged': 'お申込み額',
  'Texts.portfolio.fund.applied-units': 'お申込み数量',
  'Texts.portfolio.fund.confirmed-amount-pledged': 'ご入金必要額',
  'Texts.portfolio.fund.confirmed-units': '当選数量',
  'Texts.portfolio.fund.name': 'ファンド名',
  'Texts.portfolio.fund.name.cb-self-offering': '社債名',
  'Texts.portfolio.fund.owned-amount': '保有額',
  'Texts.portfolio.fund.owned-units': '保有数量',
  'Texts.portfolio.fund.purchased-amount': '購入額',
  'Texts.portfolio.fund.status.canceled': 'キャンセル済み',
  'Texts.portfolio.fund.status.completed': '投資中',
  'Texts.portfolio.fund.status.confirmed': 'ご入金待ち',
  'Texts.portfolio.fund.status.confirming': '本申込み待ち',
  'Texts.portfolio.fund.status.failed': '案件不成立',
  'Texts.portfolio.fund.status.operation-ended': '運用終了',
  'Texts.portfolio.fund.status.processing': '購入済み',
  'Texts.portfolio.fund.status.redeemed': '償還済み',
  'Texts.portfolio.fund.status.rejected': '落選',
  'Texts.portfolio.fund.status.requested': 'お申込み内容確認中',
  'Texts.portfolio.fund.status.transferred-or-redeemed': '移転/償還済み',
  'Texts.portfolio.interest': '利払い',
  'Texts.portfolio.liquidation': '精算',
  'Texts.portfolio.memo': 'メモ',
  'Texts.portfolio.owned-assets': '保有資産',
  'Texts.portfolio.payment-amount': '入金額',
  'Texts.portfolio.payment-date': '入金日',
  'Texts.portfolio.payment-history': '入出金履歴',
  'Texts.portfolio.payment-total': '合計入金額',
  'Texts.portfolio.payment-type': '支払い方法',
  'Texts.portfolio.payment-type.point': 'ポイント',
  'Texts.portfolio.pre-entry': '抽選申込に進む',
  'Texts.portfolio.pre-tax-amount': '利払い・償還額(税引き前)',
  'Texts.portfolio.raffle-result': '抽選結果',
  'Texts.portfolio.redemption': '償還',
  'Texts.portfolio.refund': '払い戻し',
  'Texts.portfolio.title': 'ポートフォリオ',
  'Texts.portfolio.total': '利払い・償還額',
  'Texts.portfolio.transfer': '詳細',
  'Texts.portfolio.transfer-account': 'ご入金先口座',
  'Texts.portfolio.transfer-account.not-found': '表示できる情報がありません。',
  'Texts.portfolio.transfer.instructions':
    '下記の銀行口座に、上記振込必要額のご入金をお願いいたします。',
  'Texts.portfolio.transfer.investor-bank':
    '銀行名: {bankName} <br/> \n' +
    '支店名: {branchName} <br/> \n' +
    '口座種別: {accountType} <br/> \n' +
    '口座番号*: {accountNumber} <br/> \n' +
    '口座名義: {accountName}',
  'Texts.portfolio.transfer.virtual-bank-account':
    '銀行名: GMOあおぞら銀行 <br/> \n' +
    '支店名: {branchNameKana} <br/> \n' +
    '口座種別: 普通 <br/> \n' +
    '口座番号: {accountNumber} <br/> \n' +
    '口座名義: {accountName} <br/> \n' +
    '入金期限: {transferExpiredAt}',
  'Texts.portfolio.transfer.japanpost-note':
    "*ゆうちょ銀行の場合、口座番号は振込用の7桁の番号を表示しています。<br />参考: <a href='https://www.jp-bank.japanpost.jp/kojin/sokin/koza/kj_sk_kz_furikomi_ksk.html' target='_blank'>https://www.jp-bank.japanpost.jp/kojin/sokin/koza/kj_sk_kz_furikomi_ksk.html</a>",
  'Texts.portfolio.type': '種別',
  'Texts.portfolio.unit': '{unit} 単位',
  'Texts.portfolio.withdraw': '出金',
  'Texts.portfolio.withholding-tax': '源泉徴収額',
  'Texts.pre-entry.complete.back-button': '社債一覧に戻る',
  'Texts.pre-entry.complete.card.title': 'お申込みが完了しました',
  'Texts.pre-entry.complete.description':
    'お申し込みありがとうございます。抽選実施後に結果を記載したメールをお客様のメールアドレスに送信いたしますので、当該メールを必ずご確認いただけますようお願い申し上げます。',
  'Texts.pre-entry.complete.title': '完了',
  'Texts.pre-entry.condition-explanation':
    '本社債へのお申し込みにあたり、下記の条件にご同意をお願いいたします。条件にご同意いただける場合、以下のチェックボックスにチェックを入れて次のステップにお進みください。 当社は、目論見書を電子交付いたします。 ※電子交付とは、目論見書に記載された事項を、次ページでご覧いただき、お客様のパソコン又はスマートフォンにダウンロードしていただくことをいいます。なお、ダウンロードにあたってはSafari、Google Chrome、Microsoft Edge等のブラウザが、閲覧にあたってはAdobe Acrobat Reader 4.0以降等のPDF閲覧ソフトが必要となります。 当社は、支払通知書を電子交付いたします。 ※電子交付とは、支払通知書に記載された事項を、オンラインサービス上でご覧いただくことまたはオンラインサービスが利用できない場合にあっては［メール送信代行業社名］から電子メールで当該事項を送信し、当該電子メールを受信したお客様のパソコンもしくはスマートフォンに当該事項を保存していただくことをいいます。なお、オンラインサービス上でご覧いただく場合においてはPDF形式にて交付いたしますので、ダウンロードにあたってはSafari、Google Chrome、Microsoft Edge等のブラウザが、閲覧にあたってはAdobe Acrobat Reader 4.0以降等のPDF閲覧ソフトが必要となります。また、オンラインサービスが利用できない場合において、お客様が当該事項をパソコンもしくはスマートフォンに保存するにあたっては、Adobe Acrobat Reader 4.0以降等のPDF閲覧ソフトが必要となります。 [個人情報共有] [マイナンバー収集]',
  'Texts.pre-entry.confirm.fund-name': '社債名',
  'Texts.pre-entry.confirm.invest': '上記内容で申込む',
  'Texts.pre-entry.confirm.invest-amount': 'お申込み金額',
  'Texts.pre-entry.confirm.invest-unit': 'お申込み数量',
  'Texts.pre-entry.confirm.title': 'お申込み内容確認',
  'Texts.pre-entry.description.agree': '上記条件に同意します',
  'Texts.pre-entry.description.title': '条件確認',
  'Texts.pre-entry.documents.checked': '上記書類をダウンロードし、内容について同意しました。',
  'Texts.pre-entry.documents.description':
    '下記の商品内容説明書及び重要説明事項（抽選申込み時）を投資家様のパソコン又はスマートフォンにダウンロードし、内容をご確認のうえ、以下のチェックボックスにチェックを入れて次のステップにお進みください。',
  'Texts.pre-entry.documents.title': '書類ダウンロード',
  'Texts.pre-entry.form.notes.content':
    '・このページで抽選にお申し込みいただくと、上記の数量および金額に対する当選数量および当選額を「ポートフォリオ」画面および「本申し込み」画面でお知らせします。抽選結果発表後にお客様に行っていただく本申し込みの際は、当選数量および当選額にて購入をお申し込みいただくことになり、当該数量又は金額と異なるお申し込みをいただくことはできません。<br />・お客様はこの抽選への申し込みが完了した後であっても、当該申し込みをキャンセルすることができます。',
  'Texts.pre-entry.form.notes.title': '注意事項',
  'Texts.pre-entry.form.title': '抽選申込',
  'Texts.profile.bank-account': '金融機関口座情報',
  'Texts.profile.compatibility': '投資経験など',
  'Texts.profile.documents': '書類',
  'Texts.profile.documents.support-formats': '対応形式：JPG, PNG',
  'Texts.profile.general-documents.description':
    '変更いただいた項目について記載のある、公的証明書をアップロードしてください。受付可能な証明書は、運転免許証、運転経歴証明証、住民基本台帳カード、マイナンバーカード 、健康保険証、在留カード、特別永住者証明書、のいずれかです。健康保険証を提出いただく際には被保険者等記号・番号等をマスキングして提出する必要があります。',
  'Texts.profile.general-information': '基本情報',
  'Texts.profile.otherItems': 'その他情報',
  'Texts.profile.title': 'プロフィール',
  'Texts.profile.update': '{item}変更',
  'Texts.profile.upload-documents.format':
    'jpg、png形式のいずれかのファイルのみアップロードいただけます。アップロードできるファイルサイズは１サイズあたり最大5MBです。',
  'Texts.profile.upload-documents.type': '書類種別',
  'Texts.profile.upload-documents.type.driver-history-certificate': '運転経歴証明書',
  'Texts.profile.upload-documents.type.driver-license': '運転免許証',
  'Texts.profile.upload-documents.type.health-insurance-card': '保険証',
  'Texts.profile.upload-documents.type.my-number-card': 'マイナンバーカード',
  'Texts.profile.upload-documents.type.passport': 'パスポート',
  'Texts.profile.upload-documents.type.residence-permit': '在留カード',
  'Texts.profile.upload-documents.type.resident-record-card': '住民基本台帳カード',
  'Texts.profile.upload-documents.type.special-permanent-resident-certificate': '特別永住者証明書',
  'Texts.registration-progress.bank': '金融機関口座確認',
  'Texts.registration-progress.compatibility': '投資経験などの入力',
  'Texts.registration-progress.create-account': 'アカウント作成',
  'Texts.registration-progress.description':
    '案件に応募いただくためには、全ての登録を済ませていただく必要があります。',
  'Texts.registration-progress.kyc': '本人確認',
  'Texts.registration-progress.rejected-message': '投資家登録の審査に通りませんでした',
  'Texts.registration-progress.status.completed': '完了',
  'Texts.registration-progress.status.not-yet': '未入力',
  'Texts.registration-progress.status.not-yet-disable': '未入力',
  'Texts.registration-progress.status.pending': '審査中',
  'Texts.registration-progress.status.registered': '入力済み',
  'Texts.registration-progress.status.reverted': '要修正',
  'Texts.registration-progress.status.waiting-kyc': 'eKYC待ち',
  'Texts.registration-status.processing': '登録中',
  'Texts.registration-status.rejected': '審査落ち',
  'Texts.registration-status.reverted': '要修正',
  'Texts.registration-status.update-request-rejected': '変更依頼却下',
  'Texts.registration-status.update-requested': '変更依頼中',
  'Texts.registration-status.verified': '登録完了',
  'Texts.registration.agree-personal-info-policy':
    '<a href="{url}" target=”_blank”>個人情報保護方針</a>に同意します。',
  'Texts.registration.already-have-account': '既にアカウントをお持ちですか？',
  'Texts.registration.bank.documents': '書類',
  'Texts.registration.bank.eposnet.button': 'エポスネットログインはこちらをクリック',
  'Texts.registration.bank.eposnet.confirmation':
    'エポスカードへ登録の口座情報に間違いが無ければ次のステップに進んでください。',
  'Texts.registration.bank.eposnet.description':
    '利子・償還金はエポスNetにご登録されている口座にお振込をさせていただきます。',
  'Texts.registration.bank.eposnet.description.invalid-account':
    '金融機関口座情報が取得できません。エポスネットに金融機関口座情報が登録されていることを確認のうえ、再度実行してください。',
  'Texts.registration.bank.japanpost-note':
    "*ゆうちょ銀行の場合、口座番号は振込用の7桁の番号を表示しています。<br />参考: <a href='https://www.jp-bank.japanpost.jp/kojin/sokin/koza/kj_sk_kz_furikomi_ksk.html' target='_blank'>https://www.jp-bank.japanpost.jp/kojin/sokin/koza/kj_sk_kz_furikomi_ksk.html</a>",
  'Texts.registration.bank.none-bank-name': '名称登録なし（銀行コード:{code}）',
  'Texts.registration.bank.none-branch-name': '名称登録なし（支店コード:{code}）',
  'Texts.registration.bank.subtitle': '金融機関口座情報をご入力ください',
  'Texts.registration.bank.support-formats': '対応形式：JPG, PNG, PDF',
  'Texts.registration.bank.title': '金融機関口座情報の入力',
  'Texts.registration.bank.upload-documents-description':
    '上記口座のキャッシュカードの表面と裏面、ネットバンキングの残高証明書・預金名義と口座番号がわかる資料、または通帳の表紙と表紙裏面、のいずれかの組み合せの資料をアップロードしてください。',
  'Texts.registration.bank.upload-documents-formats':
    'jpg, png, pdf形式のいずれかのファイルのみアップロードいただけます。アップロードできるファイルサイズは１サイズあたり最大5MBです。',
  'Texts.registration.compatibility.no': '無し',
  'Texts.registration.compatibility.subtitle': '投資経験についてご入力ください',
  'Texts.registration.compatibility.title': '投資経験などの入力',
  'Texts.registration.compatibility.yes': '有り',
  'Texts.registration.confirm.agree': '上記内容に間違いありません',
  'Texts.registration.confirm.subtitle': 'ご入力内容をご確認ください',
  'Texts.registration.confirm.title': '入力内容確認',
  'Texts.registration.electronic-delivery.agree': '電子的交付の説明の内容を理解しました',
  'Texts.registration.electronic-delivery.title': '電子的交付の説明',
  'Texts.registration.kyc.android-supported-browser':
    'Chrome v66以上、Firefox v66以上、Opera v47以上、Samsung internet v9以上',
  'Texts.registration.kyc.android-supported-os': 'Android 6.0以上',
  'Texts.registration.kyc.description':
    '本人確認のため、eKYCを実施いただきます。eKYCの機能は株式会社ポラリファイが提供しており、下記ボタンを押すと、ポラリファイ社の画面に遷移します。',
  'Texts.registration.kyc.ios-supported-browser': 'Safari v11.0以上',
  'Texts.registration.kyc.ios-supported-os': 'iPhone（iOS）11.0以上',
  'Texts.registration.kyc.start-ekyc': 'eKYCを実施する',
  'Texts.registration.kyc.subtitle': 'eKYCの開始',
  'Texts.registration.kyc.support-description': 'サポート対象のOS、ブラウザは以下の通りです。',
  'Texts.registration.kyc.title': '本人確認の実施',
  'Texts.registration.link-expired': 'ログインリンクの有効期限が切れました',
  'Texts.registration.link-expired-description':
    'パスワード設定リンクの有効期限が切れました。パスワード設定リンクは{expiresIn}時間で有効期限が切れます。また、一度しかご利用いただけません。ユーザー登録される場合、再度メールアドレスをご登録ください。',
  'Texts.registration.next': '次',
  'Texts.registration.next-step': '次のステップに進む',
  'Texts.registration.not-received-email': 'メールが届きませんか？',
  'Texts.registration.other-information.any-item': '任意項目',
  'Texts.registration.other-information.subtitle': 'その他の項目',
  'Texts.registration.other-information.title': 'その他情報の入力',
  'Texts.registration.password': 'パスワード',
  'Texts.registration.password.lowercase': '1つの小文字',
  'Texts.registration.password.min-length': '{min}文字以上',
  'Texts.registration.password.number': '1つの数字',
  'Texts.registration.password.uppercase': '1つの大文字',
  'Texts.registration.profile.first-name': '名',
  'Texts.registration.profile.first-name-kana': '名（カナ）',
  'Texts.registration.profile.first-name-kana-placeholder': 'タロウ',
  'Texts.registration.profile.first-name-placeholder': '太郎',
  'Texts.registration.profile.last-name': '姓',
  'Texts.registration.profile.last-name-kana': '姓（カナ）',
  'Texts.registration.profile.last-name-kana-placeholder': 'ヤマダ',
  'Texts.registration.profile.last-name-placeholder': '山田',
  'Texts.registration.profile.subtitle': '本人確認情報をご入力ください',
  'Texts.registration.profile.title': '本人確認情報の入力',
  'Texts.registration.re-send-email': '確認用のメールを再送する',
  'Texts.registration.resident-of-japan': '私は日本の居住者です。',
  'Texts.registration.temporary-registration': 'ユーザー仮登録する',
  'Texts.registration.temporary-registration-description':
    'ご入力いただいたメールアドレスにパスワード設定用のリンクを送付します。24時間以内に、パスワードを設定してください。',
  'Texts.registration.terms-of-use.agree': '利用規約に同意します',
  'Texts.registration.terms-of-use.title': '利用規約',
  'Texts.registration.verify-email': 'メールを確認する',
  'Texts.registration.verify-email-description':
    '{email} にメールアドレス確認用のメールを送付しましたのでご確認ください。しばらく経っても届かない場合、スパムフォルダに入っていないかご確認ください。',
  'Texts.registration.verify-email.go-to-next': '次のステップに進む',
  'Texts.registration.verify-kyc-status.description.ekyc-complete':
    'お手続きいただきありがとうございます。\\nお申込み内容を弊社で確認させていただきます。\\n投資が可能となりましたら電子メールでご連絡いたします。',
  'Texts.registration.verify-kyc-status.description.ekyc-error':
    '端末を変更してeKYCを再実施いただくか、下記のサポートメールまでご連絡ください。',
  'Texts.registration.verify-kyc-status.description.mail-sent':
    'お手続きいただきありがとうございます。\\nお申込み内容を弊社で確認させていただいたのち、ご入力いただいた住所にハガキを送付いたします。\\nハガキをお受け取りいただきしばらくしますと投資が可能となります。',
  'Texts.registration.verify-kyc-status.go-to-top': 'トップに戻る',
  'Texts.registration.verify-kyc-status.restart-ekyc': 'eKYCを再実施する',
  'Texts.registration.verify-kyc-status.subtitle.error': 'eKYCエラー終了',
  'Texts.registration.verify-kyc-status.subtitle.success': 'eKYC完了',
  'Texts.registration.verify-kyc-status.support-email': 'support@example.com',
  'Texts.registration.verify-kyc-status.title': '本人確認の実施結果',
  'Texts.reset-password.enter-new-password': '新しいパスワードを入力してください',
  'Texts.reset-password.link-expired': 'パスワード再設定用リンクの有効期限が切れました',
  'Texts.reset-password.link-expired-description':
    'パスワード再設定用リンクは使用されなかったため有効期限が切れました。 パスワード再設定用リンクは{expiresIn}時間後に有効期限が切れ、また一度しか使用できません。 パスワードを再設定するにはリンクを再発行してください。',
  'Texts.reset-password.not-received-email': 'メールが届きませんか？',
  'Texts.reset-password.password': 'パスワード',
  'Texts.reset-password.re-send-email': '再設定用のメールを再送する',
  'Texts.reset-password.reset-password': 'パスワードを再設定する',
  'Texts.reset-password.reset-password-description':
    'アカウントをお持ちの場合、パスワードを再設定するためのリンクが {email} に送信されました。メールの案内に従ってパスワードを再設定してください。',
  'Texts.reset-password.send-recovery-link': 'パスワード再設定用のリンクを送信する',
  'Texts.security.change-password': 'パスワードを変更する',
  'Texts.security.current-password': '現在のパスワード',
  'Texts.security.disable-two-factor-authentication': '二要素認証を無効にする',
  'Texts.security.enable-two-factor-authentication': '二要素認証を有効にする',
  'Texts.security.new-password': '新しいパスワード',
  'Texts.security.password': 'パスワード',
  'Texts.security.require-auth': 'ユーザー登録済みの方はログインしてください。',
  'Texts.security.title': 'セキュリティ',
  'Texts.security.two-factor-authentication': '二要素認証',
  'Texts.security.two-factor-authentication.description':
    '二要素認証を有効にしてアカウントのセキュリティを強化することが可能です。',
  'Texts.unit.day': '{day}日',
  'Texts.unit.month': '{month}月',
  'Texts.unit.months': '{months}ヶ月',
  'Texts.unit.year': '{year}年',
  'Texts.unit.years': '{years}年',
  'Texts.zip-code': '郵便番号',
  'Texts.zip-code-placeholder': '1112222',
  'Texts.badges.required': '必須',
  'Texts.badges.optional': '任意',
  'Texts.empty-search-result': '検索結果なし',
  'Texts.not-found.title': 'お探しのページが見つかりません',
  'Texts.not-found.description':
    'アクセスしようとしたページは存在しないか、移動された可能性があります。URLが正しいことを確認するか、またはホームに戻り必要なページにアクセスしてください。',
  'Toasts.2fa-disabled-success': '二要素認証が正常に無効化されました。',
  'Toasts.2fa-enabled-success': '二要素認証が正常に有効化されました。',
  'Toasts.complete-kyc': 'eKYCの実行が完了しました。',
  'Toasts.copiable-input-copied': 'コピーしました。',
  'Toasts.email-successfully-sent': '電子メールが送信されました。',
  'Toasts.profile.update-request.success': '{data}の更新を依頼しました。',
  'Toasts.register-profile-success': '本人情報の登録が完了しました。',
  'Toasts.update-success': '{data}が更新されました。',
  'Toasts.update-success-simple': '更新が完了しました。',
  'Toasts.wallet-sign-canceled': 'ウォレット登録のトランザクションの承認をキャンセルしました',
  'Toasts.network-change-canceled': 'ウォレットのネットワークの切り替えをキャンセルしました',
  'Toasts.wallet-already-exists': 'ウォレットは既に存在します',
  'Toasts.wallet-sign-timeout': '署名がタイムアウトしました。5分以内で署名してください',
  'Toasts.wallet-sign-failed': '署名の検証に失敗したため、ウォレットが追加されませんでした',
};

const storyStoreState: RootState = {
  setting: {
    isLoading: false,
    setting: {
      general: {
        images: {
          logo: '',
        },
      },
      variables: {
        mainCurrency: 'JPY',
        dashboardMode: VariableDtoDashboardModeEnum.Ftk,
        loginProviderConfig: {
          type: LoginProviderConfigTypeEnum.EmailPassword,
        },
      },
    },
  },
} as RootState;

export default storyStoreState;
