import { useSettings } from '@state/setting/hooks';
import { LocalStorageKeys, useLocalStorage } from './local-storage.hook';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PATH_AUTH, PATH_ROOT } from '@routes';
import { refreshSession, resetUserSession } from '@state/auth/actions';

export function useCrossTabLogin() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isSSO } = useSettings();

  const handleStorageUpdate = ({ key, newValue }: StorageEvent) => {
    if (key === LocalStorageKeys.LoggedInExternalId) {
      // New login event in another tab
      if (newValue) {
        if (isSSO) {
          dispatch(resetUserSession());
          history.push(PATH_AUTH.login);
          return;
        }
        dispatch(refreshSession());
        history.push(PATH_ROOT);
        return;
      }

      // Logout event happened in another tab
      history.push(PATH_AUTH.logout);
    }
  };

  useLocalStorage({ onEvent: handleStorageUpdate });
}
