import React, { Suspense, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom';
import { Loading } from '@components';
import { SessionProvider } from '@contexts/session.context';
import { patchConfig } from '@state';
import { PATH_ROOT, PATH_SAISON_CONNECT } from '@routes';
import { RedirectToHash } from '@components/RedirectToHash';
import { useI18nextReady } from '@hooks/use-i18next-ready.hook';

import 'react-toastify/dist/ReactToastify.css'; // TODO: Remove dependency if possible
import './scss/style.scss';

// Containers
const AppContainer = React.lazy(() => import('@containers/AppContainer'));

patchConfig({
  RE_CAPTCHA_KEY: process.env.REACT_APP_RE_CAPTCHA_KEY || '',
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL || '/api',
});

const logGitVersion = () => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('Git version: ', process.env.VERSION); // eslint-disable-line no-console
    console.log('Git version url:', process.env.VERSION_URL); // eslint-disable-line no-console
    console.log('Git commit hash: ', process.env.COMMITHASH); // eslint-disable-line no-console
    console.log('Git commit url: ', process.env.COMMIT_URL); // eslint-disable-line no-console
    console.log('Git branch: ', process.env.BRANCH); // eslint-disable-line no-console
    console.log('Git branch url: ', process.env.BRANCH_URL); // eslint-disable-line no-console
  }
};

const App = () => {
  const { isI18nextReady } = useI18nextReady();

  useEffect(() => {
    logGitVersion();
  }, []);

  // App is shown after i18next has successfully initialized
  if (!isI18nextReady) {
    return <Loading />;
  }

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <SessionProvider>
            <Switch>
              {/* External redirects to hash path */}
              <Route exact path={PATH_SAISON_CONNECT.oauthVerify} component={RedirectToHash} />
              <Route exact path={PATH_SAISON_CONNECT.twoFaVerify} component={RedirectToHash} />
              <Route path={PATH_ROOT}>
                <HashRouter>
                  <AppContainer />
                </HashRouter>
              </Route>
            </Switch>
          </SessionProvider>
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;
