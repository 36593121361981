import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IErrorResponse } from '@state/types';
import {
  SaisonConnectLoginLinkDto,
  SaisonConnectLoginLinksDto,
  SaisonConnectStatusDto,
} from '@web-api-client';
import {
  getSaisonConnectLoginPageLinksNext,
  saisonConnectConnectNext,
  saisonConnectOAuthLoginNext,
  saisonConnectOAuthRefreshNext,
  saisonConnectOAuthStatusNext,
} from './actionTypes';

export interface ISaisonConnectState {
  readonly isLinksLoading: boolean;
  readonly isLinksError: boolean;
  readonly linksError: IErrorResponse | null;
  readonly loginPageLinks: SaisonConnectLoginLinkDto[] | null;

  readonly isConnectLoading: boolean;
  readonly isConnectError: boolean;
  readonly connectError: IErrorResponse | null;

  readonly isStatusLoading: boolean;
  readonly isStatusError: boolean;
  readonly statusError: IErrorResponse | null;
  readonly status: SaisonConnectStatusDto | null;

  readonly isLoginLoading: boolean;
  readonly isLoginError: boolean;
  readonly loginError: IErrorResponse | null;

  readonly isRefreshLoading: boolean;
  readonly isRefreshError: boolean;
  readonly refreshError: IErrorResponse | null;
}

export interface IExternalSystemIntegrationsState {
  readonly saisonConnect: ISaisonConnectState;
}

export const initialExternalSystemIntegrationsState: IExternalSystemIntegrationsState = {
  saisonConnect: {
    isLinksLoading: false,
    isLinksError: false,
    linksError: null,
    loginPageLinks: null,

    isConnectLoading: false,
    isConnectError: false,
    connectError: null,

    isStatusLoading: false,
    isStatusError: false,
    statusError: null,
    status: null,

    isLoginLoading: false,
    isLoginError: false,
    loginError: null,

    isRefreshLoading: false,
    isRefreshError: false,
    refreshError: null,
  },
};

export const integrations = createReducer(initialExternalSystemIntegrationsState, builder =>
  builder
    .addCase<string, PayloadAction<void>>(getSaisonConnectLoginPageLinksNext.INIT, state => {
      state.saisonConnect.isLinksLoading = true;
      state.saisonConnect.isLinksError = false;
      state.saisonConnect.linksError = null;
    })
    .addCase<string, PayloadAction<SaisonConnectLoginLinksDto>>(
      getSaisonConnectLoginPageLinksNext.SUCCESS,
      (state, { payload }) => {
        state.saisonConnect.isLinksLoading = false;
        state.saisonConnect.isLinksError = false;
        state.saisonConnect.loginPageLinks = payload.links;
      },
    )
    .addCase<string, PayloadAction<IErrorResponse>>(
      getSaisonConnectLoginPageLinksNext.ERROR,
      (state, { payload }) => {
        state.saisonConnect.isLinksLoading = false;
        state.saisonConnect.isLinksError = true;
        state.saisonConnect.linksError = payload;
      },
    )
    .addCase<string, PayloadAction<void>>(saisonConnectConnectNext.INIT, state => {
      state.saisonConnect.isConnectLoading = true;
      state.saisonConnect.isConnectError = false;
      state.saisonConnect.connectError = null;
    })
    .addCase<string, PayloadAction<void>>(saisonConnectConnectNext.SUCCESS, state => {
      state.saisonConnect.isConnectLoading = false;
      state.saisonConnect.isConnectError = false;
      state.saisonConnect.connectError = null;
    })
    .addCase<string, PayloadAction<IErrorResponse>>(
      saisonConnectConnectNext.ERROR,
      (state, { payload }) => {
        state.saisonConnect.isConnectLoading = false;
        state.saisonConnect.isConnectError = true;
        state.saisonConnect.connectError = payload;
      },
    )
    .addCase<string, PayloadAction<void>>(saisonConnectOAuthStatusNext.INIT, state => {
      state.saisonConnect.isStatusLoading = true;
      state.saisonConnect.isStatusError = false;
      state.saisonConnect.statusError = null;
    })
    .addCase<string, PayloadAction<SaisonConnectStatusDto>>(
      saisonConnectOAuthStatusNext.SUCCESS,
      (state, { payload }) => {
        state.saisonConnect.isStatusLoading = false;
        state.saisonConnect.isStatusError = false;
        state.saisonConnect.statusError = null;
        state.saisonConnect.status = payload;
      },
    )
    .addCase<string, PayloadAction<IErrorResponse>>(
      saisonConnectOAuthStatusNext.ERROR,
      (state, { payload }) => {
        state.saisonConnect.isStatusLoading = false;
        state.saisonConnect.isStatusError = true;
        state.saisonConnect.statusError = payload;
      },
    )
    .addCase<string, PayloadAction<void>>(saisonConnectOAuthLoginNext.INIT, state => {
      state.saisonConnect.isLoginLoading = true;
      state.saisonConnect.isLoginError = false;
      state.saisonConnect.loginError = null;
    })
    .addCase<string, PayloadAction<void>>(saisonConnectOAuthLoginNext.SUCCESS, state => {
      state.saisonConnect.isLoginLoading = false;
      state.saisonConnect.isLoginError = false;
      state.saisonConnect.loginError = null;
    })
    .addCase<string, PayloadAction<IErrorResponse>>(
      saisonConnectOAuthLoginNext.ERROR,
      (state, { payload }) => {
        state.saisonConnect.isLoginLoading = false;
        state.saisonConnect.isLoginError = true;
        state.saisonConnect.loginError = payload;
      },
    )
    .addCase<string, PayloadAction<void>>(saisonConnectOAuthRefreshNext.INIT, state => {
      state.saisonConnect.isRefreshLoading = true;
      state.saisonConnect.isRefreshError = false;
      state.saisonConnect.refreshError = null;
    })
    .addCase<string, PayloadAction<void>>(saisonConnectOAuthRefreshNext.SUCCESS, state => {
      state.saisonConnect.isRefreshLoading = false;
      state.saisonConnect.isRefreshError = false;
      state.saisonConnect.refreshError = null;
    })
    .addCase<string, PayloadAction<IErrorResponse>>(
      saisonConnectOAuthRefreshNext.ERROR,
      (state, { payload }) => {
        state.saisonConnect.isRefreshLoading = false;
        state.saisonConnect.isRefreshError = true;
        state.saisonConnect.refreshError = payload;
      },
    ),
);
