import { isEmpty } from 'lodash';
import type { Path } from './types';

export * from './paths';
export * from './wizard-steps';
export type { Path, Route } from './types';

export const getPath = (path: Path, params?: Record<string, string | number>) => {
  if (!params || isEmpty(params)) return path;

  return Object.entries(params).reduce((acc, [key, param]) => {
    return acc.replace(`:${key}`, param.toString());
  }, path);
};
