import { useState, useEffect } from 'react';
import i18n from 'i18next';

export function useI18nextReady() {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    // Listen for the 'initialized' event to know when i18next is ready
    const handleI18nInitialized = () => {
      setIsReady(true);
    };

    // Register the event listener
    i18n.on('initialized', handleI18nInitialized);

    // Cleanup the event listener when the component unmounts
    return () => {
      i18n.off('initialized', handleI18nInitialized);
    };
  }, []);

  return { isI18nextReady: isReady };
}
